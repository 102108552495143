import React, { Component } from 'react'
import './stylesheets/dealsandPromotions.css'
import HeaderContainer from '../../components/header/headerContainer'
import jumpTo,{jumpToData} from '../../modules/Navigation'
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'


export default class dealsandPromotions extends Component {
  constructor(props) {
    super(props);
    this.state ={
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    }
    this.props.getProductCountDeal()
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    
  }
  render() {

    const logocompany = require('../../assets/images/d_logo.png');

    return (
      <span >
      <HeaderContainer />
      <Translator 
            from={this.state.preselectedLanguage}
            to={this.state.selectedLanguage}
            googleApiKey={serverUrl.googleApiTranslation}
          >
          <a href onClick={() => jumpTo('/marketplace-home')} className="GBX_logo dealsLogo">
            <img src={logocompany} alt=""/>
          </a>
          <div className="Our_heading container pt-2">
            <div className="sub_back text-left intr_pges">
              <a href onClick={() => jumpTo('landing-marketplace')} className="land_back dealsBackBtn"><i className="fas fa-arrow-left"></i><span><Translate>BACK</Translate></span></a>
              <h2 className="shop_head deals_head "><Translate>Deals and Promotions</Translate></h2>
            </div>
          </div>
          <div className="shopby dealsBody">
            <div className="HomeContainer">
                <div className="row margin_7">
                  <div className="col-sm-6 dealsContainer">
                    <a className="recommend_box meg-brand-pr_box dealsBox" title="Coming Soon">
                      <div className="recommend_txt">
                        <div className="recommend-icon deals-icon"><i className="fas fa-solid fa-bullhorn"></i></div>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title"><Translate>Products Of The Day</Translate></p>
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-6 dealsContainer">
                    <a className="recommend_box meg-brand-pr_box dealsBox" title="Coming Soon">
                      <div className="recommend_txt">
                        <div className="recommend-icon"><i className="fas fa-solid fa-star"></i></div>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title"><Translate>Products Of The Week</Translate></p>
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-6 dealsContainer">
                    <a onClick={() => jumpTo('/featured-list')} className="recommend_box produc-pr_box dealsBox">
                    <div className="recommend_txt">
                      <div className="recommend-icon supportIcon"><i className="fas fa-shopping-basket"></i></div>
                      <h2 className="homeNumber">
                      {this.props.productCountDeal !== null ? this.props.productCountDeal.reqestData.FeaturedProductCount :''}
                      </h2>
                      <span className="recommend-border"></span>
                      <p className="recommend-below-title"><Translate>Featured Products</Translate></p>
                    </div>
                    </a>
                  </div>
                  <div className="col-sm-6 dealsContainer">
                    <a href onClick={() => jumpTo('/deals-promotion')} className="recommend_box discount-pr_box dealsBox">
                      <div className="recommend_txt">
                        <div className="recommend-icon supportIcon"><i className="fas fa-percentage"></i></div>
                        <h2 className="homeNumber">
                        {this.props.productCountDeal !== null ? this.props.productCountDeal.reqestData.DiscountedProductCount :''}
                        </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title"><Translate>Discounted Products</Translate></p>
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-6 megaBrandBox">
                    <a className="recommend_box meg-brand-pr_box dealsBox" title="Coming Soon">
                      <div className="recommend_txt">
                        <div className="recommend-icon"><i className="fas fa-weight-hanging"></i></div>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title"><Translate>Mega Brands</Translate></p>
                      </div>
                    </a>
                  </div>
                </div>         
            </div>
          </div>
          <div className="partner_footer_label ">
            <p className="partner_contentCookies"><Translate>OFFICIAL PARTNER OF THE ROMANIAN RUGBY FEDERATION</Translate></p>
          </div>
          </Translator>
      </span>
    )
  }
}





