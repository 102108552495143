import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/privacyPolicy.css'
import jumpTo,{go} from '../../modules/Navigation'
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'


export default class privacyPolicy extends Component {
constructor(props) {
      super(props);
      this.state = {
          selectedLanguage:localStorage.getItem('selectedLanguage'),
          preselectedLanguage:localStorage.getItem('preselectedLanguage'),
        };
  }

componentDidMount() {
    window.scrollTo(0, 0);
  }
handlegoBack = (data) => {
    localStorage.setItem("BackVar",1);
    window.history.back();
  }
  
  render() {
    
    return (
      <>
      <HeaderContainer />
<div className="pro_midcontianer mid_container cntnt_pages">
            <div className="sub_back text-left">
              <a onClick={this.handlegoBack} className="land_back aboutUsBack"><i className="fas fa-arrow-left"></i><span> BACK</span></a>
            </div>
  <div className="container">
    <div className="col-md-12 terms">
     <Translator 
          from={this.state.preselectedLanguage}
          to={this.state.selectedLanguage}
          googleApiKey={serverUrl.googleApiTranslation}
        >
      <h2 className="dark-grey"><b><Translate>Summary of Privacy Policy under the EU-U.S. Privacy Shield</Translate></b></h2>
      <br/>
      <p>
        <Translate>This summary highlights the key points of our Privacy Statement.</Translate>
      </p>
      <p><Translate>Please note that in the context of this disclosure; we, us, our, means primarily the {serverUrl.servername} platform</Translate></p>
      <p><Translate>We care about your privacy and have a dedicated data privacy program. We do not and will not sell or rent your data unless this is required in the context of a change to our business structure.</Translate>
      </p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i><Translate>This Statement applies to our {serverUrl.servername} websites</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i> <Translate>{serverUrl.servername} DOES NOT share your personal information with any third party under any circumstances, unless such third parties are part of the {serverUrl.servername} community; such as Vendors, Affiliates, Logistics Providers, Advertiser, or our own staff.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i> <Translate>We do, however, conduct some marketing campaigns in order to promote our products and services. This marketing is aimed at our current and potential clients and partners.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i><Translate> We do, however, conduct some marketing campaigns in order to promote our products and services. This marketing is aimed at our current and potential clients and partners.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i> <Translate>We do not collect any personal information from any person under the age of 18.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i> <Translate>We employ a variety of physical, technological and administrative security safeguards designed to protect everyone’s personal information.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i> <Translate>All the sensitive data, passwords, and other sensitive information pertaining to our clients is encrypted in order to prevent its access through fraud.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i> <Translate>Please note that due to the worldwide nature of our platform, we may transfer your information to locations outside of your country.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i> <Translate>You can contact us at <a>notifications@{serverUrl.serverdomain}</a> if you want to exercise your privacy rights, have a complaint, or want to ask a question.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i> <Translate>This Statement was last updated on May 24, 2018</Translate></p>
      <br/>
      <h3><b><Translate> Introduction</Translate></b></h3>
      <p><b><Translate>We care about privacy.</Translate></b><Translate>We believe that privacy is a fundamental right for all individuals. We take the obligations that are attached to this information very seriously. We therefore have a dedicated data privacy program with privacy by design at heart.</Translate></p>
      <p><Translate>Our business model is different from that of companies that collect your personal information with an intent to monetize such data. We collect and use personal information ONLY in order to allow us to provide our customers and end users with the relevant products and services, especially as they pertain to the actual location of the user. </Translate><b><Translate> We do not and will not sell or rent your data to third parties </Translate></b><Translate>unless this might be required in the context of changes to our business structure such as a merger or acquisition.</Translate></p>
      <p><Translate>We are working to become EU-U.S. Privacy Shield certified, and thus a signatory of the Privacy Pledge, and a member of the Future of Privacy Forum.</Translate></p>
      <br/>
      <br/>
      <br/>
      <br/>
      <p><b><Translate>Who we are. </Translate></b><Translate>When we refer to “us,” “we,” “our,” or “{serverUrl.servername}” in this Statement, we mean {serverUrl.servername} Inc. and its affiliates.</Translate></p>
      <p><b><Translate>This Statement governs all our services that we provide directly to you.</Translate></b> <Translate>Whether you are browsing our websites, receive our newsletters, or any marketing material from us, this Statement governs the use of personal information for all our products and services that we provide directly to you as a so-called ‘data controller.’</Translate></p>
      <p><b><Translate>When your institution/company privacy statement/policy governs.</Translate></b> <Translate>If you are an end-user of our client and we are providing our products and services to you on our client’s (your institution/company) behalf, we are considered a ‘data processor.’ In this case your institution’s privacy statement governs the use of personal information. Our Privacy Statement does not supersede the terms of any agreements between us and your institution/company (or any other client or third party), nor does it affect the terms of any agreement between you and your institution/company.</Translate></p>
      <p><b><Translate>Changes to this Statement.</Translate></b> <Translate>From time to time we will need to update this Statement to reflect changes to our products and services, the way we operate, or to meet new legal and regulatory requirements.</Translate></p>
      <br/>
      <br/>
      <br/>

      <h3><b><Translate>How We Use Your Information</Translate></b></h3>
      <br/>
      <p><Translate>Any personal information we collect will be used SOLELY for the purpose of better personalizing our product and services offerings to you. Once again, please note that WE WILL NOT SELL, RENT, OR OTHERWISE SHARE YOUR INFORMATION WITH ANY THIRD PARTY, unless it is done for legal purposes, or in a potential case of a merger or acquisition that may take place in the future.</Translate></p>
      <br/>
      <h3><b><Translate>Website Users</Translate></b></h3>
      <br/>
      <p><Translate>{serverUrl.servername} is a worldwide platform, and therefore the site operates in all geographic market segments, which are accessible to anyone who has access to the internet. You generally can visit our website without having to log in or otherwise identify yourself, although in order for our platform to properly showcase all its features, we do need to collect information about your physical location. In order to access functionalities relating to services offered by our platform; however, you will need to log in. This will give you the full benefit of our services and will allow you to make use of all the functionalities offered by the site, as well as for your to be able to communicate with us, or other {serverUrl.servername} users, such as with {serverUrl.servername} Community and other user of the platform.</Translate></p>
      <br/>
      <h3><b><Translate>Information We Collect</Translate></b></h3>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i>  <b><Translate>Directly from you.</Translate></b> <Translate>In order for you to take advantage of all our offered functionalities, our website requires you to register and thus to sign up for an account. When you do, we ask you for information, such as your name and email address, your physical address, and your age. This information allows us to determine your eligibility for participating on our platform, as NO person younger than 18 years of age is allowed to participate on t his platform. This information is required in order to set up your account. Often you can also voluntarily provide additional information, such as a photo or biographical information, to make better use of our websites. We might also collect information you provide by completing forms or surveys on our websites. If you provide additional content through user forums, for example, when you communicate with others on {serverUrl.servername} Community, we may link this information with your personal information if you are logged in.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i>  <b><Translate>Indirectly from you.</Translate></b> <Translate>We collect information about the pages and the products you visit and how you access and use our websites using cookies and analytics tools. This information includes information about the device(s) you use to access the websites including unique device identifiers, IP address, operating system, browser, and cookies. Depending on your device settings, we will also collect information about your geographical location. </Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i>  <b><Translate>Information from third parties.</Translate></b><Translate> We receive information from affiliates in our {serverUrl.servername} group of companies, our partners, and other third parties that we use to enhance the website experience and promote our products and services. For example, we will receive information from some third parties about how well an online marketing or email campaign performed.</Translate></p>
      
      <br/>
      <h3><b><Translate>How We Use This Information</Translate></b></h3>
      <p><Translate>We use this information to provide and improve our website services as follows.</Translate></p>
      <p><b><Translate>Analytics and marketing.</Translate></b><Translate> We analyze usage information for sales and marketing purposes and for trends about our visitors and their demographics and how they use our website. This analysis is necessary to further our legitimate interest in understanding our users and how they interact with us and our website, improving our website and communication with you (including for marketing purposes).</Translate></p>
      <p><b><Translate>Personalization.</Translate></b> <Translate>We will also use your information in order to personalize the products, which is necessary in our effort to provide more contextually relevant information as you browse our website.</Translate></p>
      <p><b><Translate>Communication.</Translate></b> <Translate>If you use the {serverUrl.servername} site, {serverUrl.servername} Community, we use the contact details you provided to communicate with you and to let you communicate with us and other {serverUrl.servername} users. Typically, we use information in this way because it is required to provide this service to you and because it is in our legitimate interests to ensure our services are being offered in a manner that meets our and your high standards.</Translate></p>
      <p><Translate>We keep website usage information for as long as it is necessary for the purposes described above. This period of time will vary depending on the services and websites you use and how you interact with us. Account information is kept until you delete the account and for a limited period of 60 months afterwards. For example, we may need to keep a record of the correspondence with you if you have made a complaint for as long as it is necessary to protect us from any legal claim. Similarly, if you unsubscribe from receiving marketing communications from us, will keep a record of this to ensure you do not receive such communications in the future.</Translate></p>
      <br/>
      <h3><b><Translate>Who We Share This Information With</Translate></b></h3>
      <p><b><Translate> WE DO NOT SHARE YOUR INFORMATION WITH ANY THIRD PARTY – UNLESS THE THIRD PARTY IS A MEMBER OF THE {serverUrl.servername} COMMUNITY (VENDORS, BUYERS, AFFILIATES, ADVERTISERS, LOGISTICS PROVIDERS, etc. AND/OR UNLESS IT IS DONE FOR LEGAL PUROPSES AS INSTRUCTED BY LEGAL AUTHORITIES, OR IN A FUTURE POTENTIAL CASE OF A MERGER OR ACQUISITION</Translate></b></p>
      <p><b><Translate>Partners.</Translate></b><Translate> WE DO; however, share personal information with the vendors, buyers, logistics providers and other partners, who may use such information for product deliveries, inquiries, and other possible marketing activities.</Translate></p>
      <p><b><Translate>Vendors.</Translate></b><Translate> We also share information with our vendors and service providers and other third parties for legal or business purposes. </Translate></p>
      <br/>
      <h3><b><Translate>Vendors, Partners & Other Types of Disclosures</Translate></b></h3>
      <p><Translate>This section provides more information on how we protect your information when we engage vendors, how we share information with our partners, and in which other scenarios we may share your information with third parties.</Translate></p>
      <br/>
      <h3><b><Translate>Vendors</Translate></b></h3>
      <p><Translate>We use vendors to help us provide products and services to our clients. Where this requires access to personal information, we are responsible for the data privacy practices of the vendors. Our vendors must abide by our strict data privacy and security requirements and instructions. They are not allowed to use personal information they access or receive from us for any other purposes than as needed to carry out their work for us.</Translate></p>
      <br/>
      <h3><b><Translate>Partners</Translate></b></h3>
      <p><Translate>In some countries and regions, our products and services are offered through channel (or reselling). We might share information with them that is necessary for them to offer and provide our products and services to our current and prospective clients.</Translate></p>
      <br/>
      <br/>
      <p><Translate>Some of our products allow you to access functionalities or content provided by our content and technology partners.</Translate></p>
      
      <br/>
      <h3><b><Translate>Other types of disclosures </Translate></b></h3>
      <p><Translate>We will also share your information where required in the following circumstances. </Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i>  <b><Translate>Payments.</Translate></b> <Translate>Where you use our products and services, and subsequently make a purchase or financial transaction, we will share your payment and transaction data with banks and other organizations that process the transaction. This is done for your protection and for fraud detection and prevention or anti-money laundering purposes.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i>  <b><Translate>Changes to our business structure.</Translate></b><Translate> Where permitted by applicable law and by the contracts with our clients, we may disclose your information in the following cases:  </Translate></p>
      <p><Translate>Corporate transactions such as a merger, acquisition, sale of assets, and financing </Translate></p>
      <p><Translate>Bankruptcy, dissolution or reorganization, or in similar transactions or proceedings </Translate></p>
      <p><Translate>Steps related to the previous bullet points (for example, due diligence)</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i>  <b><Translate>Comply with law.</Translate></b><Translate>We may need to disclose your information to comply with legal or regulatory requirements and to respond to lawful requests, court orders, and legal processes. We will always aim to limit the information we provide as much as possible. Where such disclosures relate to personal information we hold on behalf of our clients, we will defer such requests to our clients where permissible.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i>  <b><Translate>Enforce our rights, prevent fraud, and for safety.</Translate></b><Translate>We may need to disclose your information to protect and defend the rights, property, or safety of us, our clients, or third parties, including enforcing contracts or policies or in connection with investigating and preventing fraud.</Translate></p>
      <p><i className="fa fa-circle fa-1" aria-hidden="true"></i>  <b><Translate>De-identified information.</Translate></b> <Translate>We may disclose aggregate or de-identified information that is no longer associated with an identifiable individual for research or to enhance and promote our products and services. For example, we may share aggregated or de-identified information with our partners or others for business or research purposes like partnering with a research firm to explore how our products are being used and how such data can be used to enhance our functionalities and further help our clients. We will implement appropriate safeguards before sharing information, which may include removing or hashing direct identifiers (e.g., your name, email address, and device ID).</Translate></p>
      <br/>
      <h3><b><Translate>Client Engagement & Marketing</Translate></b></h3>
      <br/>
      <p><b><Translate>Managing the client relationship.</Translate></b><Translate> We are collecting and storing limited personal information about the relevant contacts at our clients for invoicing, notification of product updates and maintenance, and similar purposes.</Translate></p>
      <br/>
      <h3><b><Translate>Marketing</Translate></b></h3>
      <p><b><Translate>Promotion of products and services.</Translate></b> <Translate>We conduct marketing to promote our products and services. This marketing is generally aimed at our current and potential clients, customer, and partners. However, we do not restrict activities and events to those audiences when such activities and events benefit other end users of systems, such as info pages that explain how our products can be used effectively.</Translate></p>
      <p><b><Translate>Sharing within {serverUrl.servername}.</Translate></b><Translate> We may share personal information related to marketing with the relevant {serverUrl.servername} affiliates and departments. For example, information from a local Sales team may be provided to the Global Field Marketing and Marketing Operations teams, with our Affiliates, in order to update the relevant products and other promotional communications to you.</Translate></p>
      <p><b><Translate>Sharing with vendors.</Translate></b><Translate> We may use vendors to help us organize and conduct campaigns, events, and other aspects of marketing. We will share with them only the personal information that is necessary and ensure that they are following our strict requirements for vendors.</Translate></p>
      <p><b><Translate>Marketing preferences and opt-out.</Translate></b> <Translate>Our marketing emails will include a link so that you can change your preferences and opt-out of receiving marketing communications from us. You can do this by clicking on the "Unsubscribe" link in the email footer which will direct you to our Marketing Preference Center. While it doesn’t give you the same detailed controls as our Marketing Preference Center, you can also send us email at </Translate><b><Translate>notifications@{serverUrl.servername}</Translate></b><Translate> to unsubscribe.</Translate></p>
      <br/>
      <h3><b><Translate>Online and interest-based advertising.</Translate></b></h3>
      <p><Translate>We may use third party advertising tools to collect information about your visits to our websites to serve you targeted advertisements based on your browsing history and interests on other websites and online services or on other devices you may use. In some instances, we may share a common account identifier (such as an email address or user ID) with our third-party advertising partners to help identify and contact you across devices. We and our third-party partners use this information to make the advertisements you see online more relevant to your interests, as well as to provide advertising-related services such as reporting, attribution, analytics and market research.</Translate></p>
      <p><b><Translate>Google Analytics and Advertising.</Translate></b> <Translate>We may also utilize certain forms of display advertising and other advanced features through Google Analytics, such as Remarketing with Google Analytics, Google Display Network Impression Reporting, the DoubleClick Campaign Manager Integration, and Google Analytics Demographics and Interest Reporting. These features enable us to use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the Doubleclick advertising cookie) or other third-party cookies together to inform, optimize, and display ads based on your past visits to our websites.</Translate></p>
      <br/>
      <h3><b><Translate>Security</Translate></b></h3>
      <p><Translate>We employ a variety of physical, administrative, and technological safeguards designed to protect personal information against loss, misuse, and unauthorized access or disclosure. We have dedicated information security programs and work hard to continuously enhance our technical and operational security measures.</Translate></p>
      <p><Translate>Our measures consider the sensitivity of the information we collect, use, and store, and the current state of technology. Our security measures include data encryption, firewalls, data use, and access limitations for our personnel and vendors and physical access controls to our facilities.</Translate></p>
      <p><Translate>All products and services that use payment data maintain the applicable Payment Card Industry (PCI) compliance levels. Our compliance with the PCI standards is validated during annual audits that are conducted by external auditors (so called ‘Qualified Security Assessors’).</Translate></p>
      <br/>
      <h3><b><Translate>Data Transfers & Additional Regional & Country Information</Translate></b></h3>
      <br/>
      <p><Translate>{serverUrl.servername} is a global company headquartered in Canada. Nevertheless, we do have a regional hosting strategy, which may require us to access your information from locations outside of your region and country, for support and maintenance purposes where permitted under applicable law and our contract with your institution. We understand the importance of complying with data transfer requirements. We use approved data transfer mechanisms, such as the EU-U.S. Privacy Shield (see below), to ensure the adequate protection of your information when it is transferred.</Translate></p>
      <br/>
      <h3><b><Translate>EU-U.S. Privacy Shield</Translate></b></h3>
      <p><Translate>We comply with the EU-U.S. Privacy Shield Framework regarding the collection, use, and retention of personal information from European Union member countries. Please learn more about {serverUrl.servername}’s compliance with the EU-U.S. Privacy Shield Principles here: </Translate><b><Translate>https://www.privacyshield.gov/</Translate></b> </p>
      <p><Translate>If you have any questions about our Privacy Shield Statement and related practices, please email us at notifications@{serverUrl.servername}</Translate></p>
      <br/>
      <h3><b><Translate>Your Rights</Translate></b></h3>
      <p><Translate>In the EU and many other jurisdictions, you have rights to control how your personal information is used. You may have the right to request access to, rectification of, or erasure of personal information we hold about you. In the EU, you also may have the right to object to or restrict certain types of use of your personal information and request to receive a machine-readable copy of the personal information you have provided to us.</Translate></p>
      <p><Translate>In many cases, you will be able to access your information as well as change and delete some of the information yourself by logging into your account. If you cannot access, correct, or delete the required information yourself, please contact us at: notifications@{serverUrl.servername}</Translate></p>
      <p><Translate>Please remember that many of these rights are not absolute. In some circumstances, we (or your institution) are not legally required to comply with your request because of relevant legal exemptions.</Translate></p>
      <p><Translate>In many jurisdictions, you also have the right to lodge a complaint with the local data protection authority. But please contact us first, so we can do our best in our effort to address your concern.</Translate></p>
      <br/>
      <h3><b><Translate>United States – Your California Privacy Rights</Translate></b></h3>
      <p><Translate>If you are a California resident, California Civil Code Section 1798.83 allows you to request information on how we disclose personal information to third parties for their direct marketing purposes during the immediately preceding calendar year. You may make one request each year by emailing us at: notifications@{serverUrl.servername}</Translate></p>
      <br/>
      <h3><b><Translate>Contact Us</Translate></b></h3>
      <p><Translate>If you have any questions or concerns about our Privacy Statement or data privacy practices, please contact us at: notifications@{serverUrl.servername}</Translate></p>
      </Translator>
    </div>
  </div>
  <div className="partner_footer_label ">
   <p className="partner_contentCookies"><Translate>OFFICIAL PARTNER OF THE ROMANIAN RUGBY FEDERATION</Translate></p>
  </div>
      {/* Footer */}
        <FooterContainer />
</div>
</>
    )
  }
}





