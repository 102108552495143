import React, { Component } from 'react'
import './stylesheets/topBanner.css'
import AwesomeSlider from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay'
import AwesomeSliderStyles from 'react-awesome-slider/src/styles'

export default class TopBanner extends Component {
  // const isLoggedin = 0;

  constructor(props) {
      super(props)
      this.props.getBannerData()
      // this.toggleMenu = this.toggleMenu.bind(this);
    }

  componentDidMount() {
  }

  render() {

    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const  imageDetails  = this.props.bannerImage;
    const image = [];
    if(imageDetails !== null && imageDetails !== undefined){
      var imageDataMain = imageDetails.imageData;
      var imageUrl = imageDetails.imagePath;
      for(var i = 0; i < imageDataMain.length; i++) {
        var imageData = {};
        imageData['name'] = imageUrl+imageDataMain[i];
        image.push(imageData);
      }
    }
    
    return (
      <span>
          <AutoplaySlider
              play={true}
              cancelOnInteraction={false} // should stop playing on user interaction
              interval={6000}
            >
            {imageDetails !== null && imageDetails !== undefined ? image.map(({ name}, index) => <div key={name} data-src={name}></div>) : ""}
            </AutoplaySlider>
              <div className="slider_caption"></div>
      </span>
    )
  }
}



