import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/home.css'
import Auth from '../../modules/Auth'
import jumpTo, { go } from '../../modules/Navigation'
import Modal from "react-bootstrap/Modal";
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'


export default class home extends Component {
  constructor(props) {
    super(props);
    Auth.getLocation();
    Auth.getDevice();
    this.state={
      isOpen:false,
      devicetype:localStorage.getItem('devicetype'),
      deviceos:localStorage.getItem('deviceos'),
      devicebrowser:localStorage.getItem('devicebrowser'),
      latitude: localStorage.getItem('latitude'),
      longitude:localStorage.getItem('longitude'),
      userdevice:localStorage.getItem('userdevice'),
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  cookieClick =(value)=>{
    this.props.postCookieData(this.state)
    localStorage.setItem('conditionDataHeader', value)
    localStorage.setItem('selectedLanguage','en')
    this.props.history.push('/landing-marketplace')

  }

   handleClose = () => this.setState({isOpen:false});
   handleShow = () => this.setState({isOpen:true});


  render() { 

    const rlogo = require("../../assets/images/rlogo.png");
    const marketplace = require("../../assets/images/marketplace.jpeg");
    const service = require("../../assets/images/service.jpeg");
    const generic_facebook = require("../../assets/images/generic_facebook_new.jpg");
    const promotional = require("../../assets/images/promotional.jpeg");
    const conditionDataHeader = localStorage.getItem('conditionDataHeader');
   
    if(conditionDataHeader === '1'){
      this.props.history.push('/landing-marketplace')
    }
    const ConditionData = "";
  return (
      <span>
      <HeaderContainer />
      <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
        <div className="mid_container pro_midcontianer">
          <div className="top_bannerData">
           <img src={generic_facebook} title="banner" className="ImageBanner" alt="top_banner"/>
           <h1 className="centeredData">{serverUrl.servername}</h1>
           <h1 className="centered">ANY STORE AT YOUR DOOR</h1>
          </div>
            <div className="containerData" align= "center">
              <img src={rlogo} title="logo" className="mainLogo" />
            </div>

              <div className="container">
               <h1 className="main_heading homeMainHeading"><Translate>Welcome to the IoC - Internet of Commerce</Translate></h1>
               {conditionDataHeader !== "1"?
          <div className="footer_label ">
            <p className="contentCookies"><Translate>We use cookies to better understand how you use our site, and to improve your overall experience. </Translate><br/><Translate>This includes personalizing content-advertising.To continue using our site you need to agree with our revised: </Translate><br/>
             <a className="disclaimerText" href = "/privacy-policy" onClick={() => jumpTo('/privacy-policy')}><Translate>Privacy Policy</Translate></a><Translate> and </Translate><a className="disclaimerText" href = "/terms-condition" onClick={() => jumpTo('/terms-condition')}><Translate>Terms of Use</Translate></a> </p>
           <button type="button" className="btn btn-danger DisagreeBtn" onClick={this.handleShow}><Translate>DISAGREE</Translate></button>
           <button type="button" className="btn btn-success AgreeBtn" onClick={() => this.cookieClick(1)} ><Translate>AGREE</Translate></button>
          </div>
          :""}
                <div className="categ_boxes">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-4">
                        <a><img src={marketplace} className="ImageData" alt="Snow"/>
                        <h3 className="centeredMain">Find Your Product</h3></a>
                      </div>
                            <div className="col-sm-4">
                             <a><img src={service} className="ImageData" alt="Forest"/>
                             <h3 className="centeredMain">Find Your Service</h3></a>
                            </div>
                            <div className="col-sm-4">
                             <a><img src={promotional} className="ImageData" alt="Mountains"/>
                             <h3 className="centeredMain">Find Your Promotion</h3></a>
                            </div>

                    </div>
                  </div>
                </div>
             </div>
        <FooterContainer/>
        </div>

      <Modal show={this.state.isOpen} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>To continue using our services please click Agree with Privacy 
            Policy</Modal.Body>
        <Modal.Footer>                 
          <button variant="primary" onClick={this.handleClose}>
            OK..
          </button>
        </Modal.Footer>
      </Modal>
        </Translator>
      </span>

    )
  }
}





