import React, { Component } from 'react'
import './stylesheets/header.css'
import Auth from '../../modules/Auth'
import jumpTo,{jumpToData} from '../../modules/Navigation';
import serverUrl from '../../configs/constant';
import {Translator, Translate} from 'react-auto-translate';

export default class Header extends Component {
  // const isLoggedin = 0;

  constructor(props) {
    super(props);
    var latitude = localStorage.getItem('latitude');
    var longitude = localStorage.getItem('longitude');
    var devicebrowser = localStorage.getItem('devicebrowser');
    var deviceos = localStorage.getItem('deviceos');
    var devicetype = localStorage.getItem('devicetype');
    var userdevice = localStorage.getItem('userdevice');
    var sendData = {
      "devicetype":devicetype,
      "deviceos":deviceos,
      "devicebrowser":devicebrowser,
      "latitude":latitude,
      "longitude":longitude
    }
    this.props.getViewSite(sendData)
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      menu: false,
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    };
  }


  componentDidMount() {
    let requestdMain = localStorage.getItem('userData');
    let requestdataNew = {};
    requestdataNew.userdata = requestdMain;

    if(requestdMain !== ''){
       this.props.getWishList(requestdataNew);
    }
    Auth.getLocation();
    Auth.getDevice();
  }


 changeLanguage(selected,preselected){
   localStorage.setItem('selectedLanguage',selected.toLowerCase());
   localStorage.setItem('preselectedLanguage',preselected.toLowerCase());
   window.location.reload()
 }
  handleClick = (offset) => {
    localStorage.setItem('isLoggedin',0);
    localStorage.setItem('userType','');
    localStorage.setItem('userName','');
    localStorage.setItem('userData','');
    localStorage.setItem('dataurl','');
    localStorage.setItem('wishListCountData','');
    window.location = 'https://'+serverUrl.serverdomain+'/backend/logout';

  }

  toggleMenu(){
    this.setState({ menu: !this.state.menu })
  }

  render() {

    const isLoggedin = localStorage.getItem("isLoggedin") || 0 ;
    const userType = localStorage.getItem('userType');
    var countryLanguage = localStorage.getItem('countryLanguage');
    var languageName = localStorage.getItem('languageName');
    var dataurl = localStorage.getItem('dataurl');
    let wishListCountData = localStorage.getItem('wishListCountData');
    var wishurl = 'https://'+serverUrl.serverdomain+'/backend/wishlist-product';
    // const conditionDataHeader = localStorage.getItem('conditionDataHeader');
    const wishlistCount = localStorage.getItem('wishlistCount');
    let userName = localStorage.getItem('userName');
    const userData = localStorage.getItem('userData');
    const show = (this.state.menu) ? "show" : "" ;
    const logo = require('../../assets/images/Arth.png');
    let requestdMain = localStorage.getItem('userData');

    if(languageName === '' || languageName === null){
        if(this.props.languageName !== null && this.props.languageName !== undefined){
           countryLanguage = this.props.languageName.languagesCode;
           languageName = this.props.languageName.languagesName;
           localStorage.setItem('countryLanguage', countryLanguage );
           localStorage.setItem('languageName', languageName );
        }
    }

    
      if(requestdMain !== ''){
        let getWishList = this.props.wishlistcount;
      if(getWishList !== null && getWishList !== undefined){
        let wishListCountData = getWishList.wishListCount;
        localStorage.setItem('wishListCountData',wishListCountData);
      }
      let wishListCountData = localStorage.getItem('wishListCountData');
    }
    // if(userType === 'Vendor' && companyName !== '' && companyName !== null){
    //    userName =companyName;
    // }else{
    //    userName =userName;
    // }

    return (
      <div className="Top_Bar">
      <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
        <div className="container">
          <div className="row">
            <div className="col-sm-3 hidden-xs Top_column">
             {isLoggedin === 0 || userName === ''?
              <div className="button_box">
                <a onClick={() =>  jumpTo('/login')} className="btn login_btn coman_btn" ><i className="far fa-user"></i><Translate>Login</Translate></a>
                <div className="dropdown" >
                  <a className="btn blue_btn coman_btn dropdown-toggle" onClick={ this.toggleMenu }>
                          <i className="far fa-user"></i> <Translate>REGISTER</Translate>
                        </a>
                        <div align="right" className={"dropdown-menu Sub_menu " + show} onClick={ this.toggleMenu }>
                          <a onClick={() => jumpToData('/register/Vendor',{registervalue:'Vendor'})}><Translate>VENDOR REGISTRATION </Translate></a>
                          <a onClick={() => jumpToData('/register/Buyer',{registervalue:'Buyer'})}><Translate>BUYER REGISTRATION</Translate></a>
                          <a  onClick={() => jumpToData('/register/Affiliate',{registervalue:'Affiliate'})}><Translate>AFFILIATE REGISTRATION</Translate></a>
                        </div>
                </div>
              </div> :
              <div className="button_box addbuttonclasshere"> 
                <div className="dropdown logged_btn" >
                  <a href="#" className="dropdown-toggle" onClick={ this.toggleMenu }>
                    <span className="circle_btn"><i className="far fa-user"></i></span><span className="user_txt">
                      {userName}
                    </span>
                  </a>
                  <div className={"dropdown-menu Sub_menu "+ show} onClick={ this.toggleMenu } >
                          <a href={dataurl}><i className="fas fa-tachometer-alt"></i>Dashboard</a>
                          <a onClick={this.handleClick}><i className="fas fa-sign-out-alt"></i>Logout</a>
                      </div>
                </div>
              </div>
              }
            </div>
            <div className="col-sm-6 col-xs-7 text-center Top_column ">          
                  <nav className="">
						  <div className="navbar-header ">
  							<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
  								<span className="icon-bar"></span> 
  								<span className="icon-bar"></span> 
  								<span className="icon-bar"></span> 
  							</button>
					    </div> 
                        <div className="collapse navbar-collapse Example_Nav1" id="bs-example-navbar-collapse-1">
                          <div className="row">
                            <div className="col-lg-4 col-sm-4 col-md-4 offset-lg-1">
                              <a onClick={() => jumpTo('/landing-marketplace')} className="media">
                                <div className="media-left">
                                  <span className="circle_btn" title="">
                                          <img src={logo} title="" alt="Arth"/>
                                       </span>
                                </div>
                                <div className="media-body">
                                        <h3 className="h3_active">GLOBAL MARKETPLACE</h3>
                                    </div>
                              </a>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-md-4">
                              <a href="" className="media" title="Coming Soon">
                                <div className="media-left">
                                  <span className="circle_btn pink_circle" title="Coming Soon">
                                          <img src={logo} title="" alt="Arth"/>
                                       </span>
                                </div>
                                <div className="media-body">
                                        <h3 title="Coming Soon">GLOBAL SERVICES</h3>
                                    </div>
                              </a>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-md-4 media_last">
                              <a href="" className="media" title="Coming Soon">
                                <div className="media-left" >
                                  <span className="circle_btn blue_circle" title="Coming Soon">
                                          <img src={logo} title="" alt="Arth"/>
                                       </span>
                                </div>
                                <div className="media-body">
                                        <h3 title="Coming Soon">GLOBAL PROMOTIONS</h3>
                                    </div>
                              </a>
                            </div>

                          </div>
                        </div>
                      
                    </nav>
                </div>
                <div className="col-sm-3 col-xs-5 text-right Top_column">
                  <div className="head_icons d-flex justify-content-end">
                    <ul className="list-inline">
                      {userType === 'Buyer' && requestdMain !==''?
                      <li className="Heart_icon"> 
                        <a href={wishurl} title="click here for WishList">
                        <i className="far fa-heart"></i>
                        {wishListCountData !== null && wishListCountData !== undefined && wishListCountData !== ''? 
                          <span className="noti_spn transition">{wishListCountData}</span> 
                          : <span className="noti_spn transition">0</span>
                        }
                        </a>
                      </li>: ""}
                      <li className="circle_box circle_Active">
                          <a href="#" className={this.state.selectedLanguage === 'en' ? 'circle_btn blue_circle  tooltip_box active' : 'circle_btn blue_circle  tooltip_box'} onClick={() => this.changeLanguage('en',countryLanguage)}>
                            en<span className="tooltiptext tooltiptext_box"><p>English</p>
                            </span>
                          </a>
                      </li>
                      { countryLanguage !== null ?
                      <li className="circle_box">
                          <a href="#" className={this.state.selectedLanguage !== 'en' && this.state.selectedLanguage !== undefined && this.state.selectedLanguage !== null ? 'circle_btn blue_circle  tooltip_box active' : 'circle_btn blue_circle  tooltip_box'} onClick={() => this.changeLanguage(countryLanguage,'en')}>
                            {countryLanguage}<span className="tooltiptext tooltiptext_box"><p><Translate>{languageName}</Translate></p>
                            </span>
                          </a>          
                      </li>
                      : ""}
                    </ul>
                  </div>
                </div>
          </div>
        </div>
        </Translator>
      </div>
    )
  }
}



