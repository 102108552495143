import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import './stylesheets/aboutUs.css'
import jumpTo,{go} from '../../modules/Navigation'
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'
import HeaderContainer from '../../components/header/headerContainer'

export default class aboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
      };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
   handlegoBack = (data) => {
    localStorage.setItem("BackVar",1);
    window.history.back();
  }
  render() {
    
    return (
      <>
      <HeaderContainer />
       <div className="pro_midcontianer mid_container cntnt_pages">
       <div className="sub_back text-left">
                    <a onClick={this.handlegoBack} className="land_back aboutUsBack"><i className="fas fa-arrow-left"></i><span> BACK</span></a>
            </div>
          <div className="container">
      <Translator 
          from={this.state.preselectedLanguage}
          to={this.state.selectedLanguage}
          googleApiKey={serverUrl.googleApiTranslation}
        >
      <div className="col-md-12 terms">
          <h2 className="dark-grey"><b><Translate>{serverUrl.servername} - About us:</Translate></b></h2>
         
      
          <p>
          <Translate>{serverUrl.servername} has been developed into a platform where just about any vendor or corporation in the world will be able to post and promote their products and services for sale. This platform offers 3 distinct components:
          </Translate></p>
          <br/>
      <p>-  <Translate>Global Marketplace</Translate></p>
      <p>-  <Translate>Global Services</Translate></p>
      <p>-  <Translate>Global Promotions</Translate></p>
      <br/>
      <p><Translate>{serverUrl.appname} brings a unique and differentiated approach to the digital commerce space, by simply developing and implementing a platform which allows vendors and service providers from all over the world to post their products and services for Sale/Services/Promotion in their respective countries and also in their respective currencies, as well as at the same time allowing buyers to acquire any item from anywhere in the world in their respective currencies.</Translate></p>
      <br/>
      <p>
        <Translate>In essence, the {serverUrl.appname} platform is intended to become a “Google”-type search engine for businesses, products, and services rendered worldwide by every corporation looking to sell or perform services either Business to Business, Business to Consumer, or even Consumer to Consumer. Moreover, {serverUrl.appname} will further facilitate a particular digital market channel which relates to Business to Government products and services offerings. This will be done at a true Global scale, and thus we anticipate that </Translate><h5><b><Translate>{serverUrl.appname} will become the very first organization in the world to facilitate trade and commerce across all national boundaries, with buyers and sellers having the ability to trade in every single country around the world.</Translate></b></h5>
      </p>
      <br/>
      <p><Translate>{serverUrl.servername} intends to redefine the internet-related commerce by shifting the traditional E-commerce (Electronic Commerce) space toward the F-commerce, which is defined as FACILITATIVE COMMERCE.</Translate></p>
      <br/>
      <p><Translate>In conclusion, it is anticipated that {serverUrl.appname} will integrate and aggregate the type of services rendered by companies such as Amazon, Alibaba, eBay, Groupon, Shopify, Craigslist, Etsy, etc. into one world-wide platform.</Translate></p>
      <br/>
      <h3><b><Translate>Services to be performed</Translate></b></h3>
      <p><Translate>The main service {serverUrl.appname} will offer is the listing, and subsequent searching and prioritization, of products and services offered by global vendors in one ‘uber’-convenient place. In actuality, the key benefit will be represented by the ease and convenience of accessing any* type of Product, Service, or Promotion that a customer may be looking for at any point in time and space on the face of the planet.</Translate></p>
      <br/>
      <p><Translate>The platform is primarily a facilitative one, without the company actually becoming a purchaser and subsequently a reseller of goods, products, and services.</Translate></p>
      <br/>
      <p><Translate>In the </Translate><b><Translate>Global Marketplace segment</Translate></b><Translate> the platform will simply facilitate the listing of all products and services from around the world in one convenient and easy to access place. Listed products will be then segmented, clustered, or sorted based on Keyword relevance, Price levels, Location, Category, Most Viewed, etc.</Translate></p>
      <br/>
      <h3><b><Translate>Main customers</Translate></b></h3>
      <p><Translate>Primary customer segments are represented on one hand by providers (vendors of goods, products, and services), and on the other hand by buyers who are interested in acquiring such products, services, or promotions. While most corporations are spending significant resources (time, money, effort) in identifying their market segments, and in subsequently further clustering those segments in order to be better able to serve the target customers, </Translate><b><Translate>{serverUrl.appname} will target just about every single corporation, company, vendor, promoter, developer, service provider, and consumer on the face of the planet.</Translate></b></p>
      <p><Translate>In fact, we are <b>looking to position {serverUrl.appname} as the foremost interactive platform for buyers and sellers everywhere in the world, and we will strive to ensure that within a decade every human being on the face of the planet will trade on this platform.</b> Maybe this is a very ambitious goal and objective, but in fact if someone was to say 20 years ago that a company named “Google” will become the best-known brand in the world within 20 years, most of the evaluators would have likely said that such a thing is quite likely impossible. Yet, today everyone knows who and what Google is, and the skeptics of 20 years past have now been proven utterly incorrect in their assumptions.</Translate></p>
      
      <br/>
      <h3><b><Translate>Company strategy and differentiation approaches</Translate></b></h3>
      <br/>
      <br/>
      <h4><b><Translate>Entrepreneur’s vision:</Translate></b></h4>
      <br/>
      <h4><Translate>Vision statement</Translate></h4>
      <br/>
      <p><b><Translate>“We aim to be the world’s leading facilitator of E-commerce and M-commerce transactions. Our interactive platform is designed to add value to the Global Community for generations to come”</Translate></b></p>
      <br/>
      <h4><Translate>Mission Statement</Translate></h4>
      <br/>
      <p><b><Translate>“Our mission is an ongoing P.R.O.C.E.S.S.</Translate>
      </b>
        <b><Translate> “Protect, Respect, Organize, Connect, Evaluate, through Superior Strategy”</Translate></b></p>
      <br/>
      <h4><Translate>Long-term objectives</Translate></h4>
      <br/>
      <p><Translate>“Our long-term objective is to facilitate the convergence of all market forces (providers and consumers) on the face of the planet, </Translate><b><Translate>on the most convenient and straightforward internet-based platform”</Translate></b></p>
      <br/>
      
      <br/>
      <h5><b>*<Translate>{serverUrl.appname} </Translate></b><Translate> will not permit or condone the commercialization of any illegal or inappropriate items</Translate></h5>
    <br/>
     
      </div>
      </Translator>
      <div className="partner_footer_label ">
                <p className="partner_contentCookies"><Translate>OFFICIAL PARTNER OF THE ROMANIAN RUGBY FEDERATION</Translate></p>
              </div>
    </div>
      {/* Footer */}
        <FooterContainer />
</div>
</>
    )
  }
}





