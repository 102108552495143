const serverCall ={
	// serverUrl:'http://localhost:3000',
	serverUrl:'https://shopglocals.com:3000',
	// serverUrl:'http://localhost:3000',
    // serverUrl:'https://globalbox.world:3000',
	googleApiTranslation:'AIzaSyB5vqyOxPiTSNhHX6dZFLM3UgvcGR0PxAI',
	serverdomain:'shopglocals.com',
	servername:'shopglocals.com',
	appname:'ShopGlocal',

} 
// const serverUrl = 'https://globalbox.world:3000';
// const googleApiTranslation = 'AIzaSyB5vqyOxPiTSNhHX6dZFLM3UgvcGR0PxAI';

export default serverCall