import React, { Component } from 'react'
import './stylesheets/landingShop.css'
import jumpTo,{jumpToData} from '../../modules/Navigation'
import { TbHeartPlus } from 'react-icons/tb';
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'
import HeaderContainer from '../../components/header/headerContainer'


export default class landingShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
        devicetype:localStorage.getItem('devicetype'),
        deviceos:localStorage.getItem('deviceos'),
        devicebrowser:localStorage.getItem('devicebrowser'),
        latitude: localStorage.getItem('latitude'),
        longitude:localStorage.getItem('longitude'),
        userdevice:localStorage.getItem('userdevice'),
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    var sendData = {
      "devicetype":this.state.devicetype,
      "deviceos":this.state.deviceos,
      "devicebrowser":this.state.devicebrowser,
      "latitude":this.state.latitude,
      "longitude":this.state.longitude,
      "userdevice":this.state.userdevice
    }
    this.props.getProductCountCity(sendData);
  }
  render() {
    const logocompany = require('../../assets/images/d_logo.png');
    const dartt = require('../../assets/images/dartt.png');
    const dart = require('../../assets/images/newDart.png');
    
    return (
      <span>
      <HeaderContainer />
        <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
          <a onClick={() => jumpTo('/marketplace-home')} className="GBX_logo cityLogo">
            <img src={logocompany}/>
          </a>

          <div className="shopIn_heading container pt-1">
            <div className="sub_back text-left intr_pges">
              <a onClick={() => jumpTo('landing-marketplace')} className="land_back shopBackBtn"><i className="fas fa-arrow-left"></i><span> BACK</span></a>
              <h2 className="shop_head cityShopHead"><Translate>Shop in: </Translate><br/> <h2 className="boldHeading">{this.props.productCount !== null ? this.props.productCount.city+", "+this.props.productCount.countryName :''}</h2></h2>
            </div>
          </div>
          <div className="shopby cityBody ">
            <div className="HomeContainer">
                <div className="row margin_7">
                  <div className="col-sm-6 cityBox" >
                    <a className="recommend_box locate_jaland NewBox"  onClick={() => jumpToData('/product-result/'+this.props.productCount.city,{cityname:this.props.productCount.city,name:this.props.productCount.city,code:this.props.productCount.countryCode})}>          
                      <div className="recommend_txt">
                        <div className="recommend-icon cityIcon"><i className="fas fa-map-marked-alt"></i></div>
                        <h2 className="numberCity">
                         {this.props.productCount !== null ? this.props.productCount.CityProductCount :''}
                        </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title"><Translate>Products LOCATED in</Translate><br/> <Translate>{this.props.productCount !== null ? this.props.productCount.city :''}</Translate></p>
                      </div>  
                    </a>
                  </div>
                  
                 
                  <div className="col-sm-6 cityBox">
                    <a className="recommend_box vendors_lo NewBox" onClick={() => jumpToData('/vendor-result/'+this.props.productCount.city,{cityname:this.props.productCount.city,name:this.props.productCount.city,code:this.props.productCount.countryCode})}>
                    <div className="hexa_logo">
                      </div>
                    <div className="recommend_txt">
                      <div className="recommend-icon cityIcon"><i className="fas fa-user-tie"></i></div>
                      <h2 className="numberCity">
                      {this.props.productCount !== null ? this.props.productCount.CityVendorCount :''}
                      </h2>
                      <span className="recommend-border"></span>
                      <p className="recommend-below-title"><Translate>Vendors LOCATED in</Translate><br/><Translate>{this.props.productCount !== null ? this.props.productCount.city :''}</Translate></p>
                    </div>  
                    </a>
                  </div>

                  <div className="col-sm-6 cityBox" >
                    <a className="recommend_box shpping_cntr NewBox" title="Coming Soon">         
                      <div className="recommend_txt">
                        <div className="recommend-icon cityIcon"><TbHeartPlus className="heartIcon"/>
                        </div>
                        <h2 className="numberCity">
                          {this.props.productCount !== null ? this.props.productCount.CityProductCount :''}
                        </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title"><Translate>Popular Products in</Translate><br/><Translate>{this.props.productCount !== null ? this.props.productCount.city :''}</Translate></p>
                      </div>  
                    </a>
                  </div>
                  <div className="col-sm-6 cityBox_1">
                    <a className="recommend_box shpping_cntr NewBox" title="Coming Soon">
                    <div className="hexa_logo">
                      </div>
                    <div className="recommend_txt">
                        <div className="recommend-icon cityIconDart">  
                           <i className="fas fa-sitemap dartIcon"></i>
                           <img className="dart" src={dartt}/>
                        </div>
                      <span className="recommend-border"></span>
                      <p className="recommend-below-title"><Translate>Popular Categories in</Translate><br/><Translate>{this.props.productCount !== null ? this.props.productCount.city :''}</Translate></p>
                    </div>  
                    </a>
                  </div>
                  <div className="col-sm-6 cityBox">
                    <a className="recommend_box vendors_lo NewBox" onClick={() => jumpToData('/product-result/'+this.props.productCount.city,{postalname:this.props.productCount.PostalCode,name:this.props.productCount.PostalCode,code:this.props.productCount.countryCode})}>
                    <div className="hexa_logo">
                      </div>
                    <div className="recommend_txt">
                      <div className="recommend-icon cityIcon"><i className="fas fa-map-marked-alt"></i></div>
                      <h2 className="numberCity">
                       {this.props.productCount !== null ? this.props.productCount.PostalCodeProductCount :''}
                      </h2>
                      <span className="recommend-border"></span>
                      <p className="recommend-below-title"><Translate>Products LOCATED in</Translate><br/>{this.props.productCount !== null ? this.props.productCount.PostalCode :''}</p>
                    </div>  
                    </a>
                  </div>
                  <div className="col-sm-6 cityBox">
                    <a className="recommend_box shpping_cntr NewBox" onClick={() => jumpToData('/vendor-result/'+this.props.productCount.city,{postalname:this.props.productCount.PostalCode,name:this.props.productCount.PostalCode,code:this.props.productCount.countryCode})}>
                    <div className="hexa_logo">
                      </div>
                    <div className="recommend_txt">
                      <div className="recommend-icon cityIcon"><i className="fas fa-user-tie"></i></div>
                      <h2 className="numberCity">
                        {this.props.productCount !== null ? this.props.productCount.PostalCodeVendorCount :''}
                        </h2>
                      <span className="recommend-border"></span>
                      <p className="recommend-below-title"><Translate>Vendors LOCATED in</Translate><br/>{this.props.productCount !== null ? this.props.productCount.PostalCode :''}</p>
                    </div>  
                    </a>
                  </div>

                  <div className="col-sm-6 cityBox">
                      <a className="recommend_box shpping_cntr NewBox" title="Coming Soon">
                    <div className="hexa_logo">
                      </div>
                    <div className="recommend_txt">
                      <div className="recommend-icon cityIcon"><TbHeartPlus className="heartIcon"/></div>
                      <h2 className="numberCity">
                       {this.props.productCount !== null ? this.props.productCount.PostalCodeProductCount :''}
                      </h2>
                      <span className="recommend-border"></span>
                      <p className="recommend-below-title"><Translate>Popular Products in</Translate><br/>{this.props.productCount !== null ? this.props.productCount.PostalCode :''}</p>
                    </div>  
                    </a>
                  </div>
                  <div className="col-sm-6 cityBox_1">
                    <a className="recommend_box shpping_cntr NewBox " title="Coming Soon">
                    <div className="hexa_logo">
                      </div>
                    <div className="recommend_txt">
                      <div className="recommend-icon cityIconDart">  
                           <i className="fas fa-sitemap dartIcon"></i>
                           <img className="dartt" src={dart}/>
                        </div>
                      <span className="recommend-border"></span>
                      <p className="recommend-below-title"><Translate>Popular Categories in</Translate><br/>{this.props.productCount !== null ? this.props.productCount.PostalCode :''}</p>
                    </div>  
                    </a>
                  </div>
                </div>
            </div>
          </div>
          <div className="partner_footer_label ">
            <p className="partner_contentCookies"><Translate>OFFICIAL PARTNER OF THE ROMANIAN RUGBY FEDERATION</Translate></p>
          </div>
          </Translator>
      </span>
    )
  }
}





