import React, { Component} from 'react'
import jumpTo,{jumpToData} from '../../modules/Navigation'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/landingMarketplace.css'
import Modal from "react-bootstrap/Modal";
import Auth from '../../modules/Auth'
import {Translator, Translate} from 'react-auto-translate';
import serverCall from '../../modules/serverCall'
import serverUrl from '../../configs/constant'

export default class landingMarketplace extends Component {
  constructor(props) {
    super(props);
    Auth.getLocation();
    this.state = {
        isOpen: false,
        keyword:'',
        productCount:'',
        category:'',
        subcategoryone:'',
        subcategorytwo:'',
        subcategorythree:'',
        devicetype:localStorage.getItem('devicetype'),
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
        deviceos:localStorage.getItem('deviceos'),
        devicebrowser:localStorage.getItem('devicebrowser'),
        latitude: localStorage.getItem('latitude'),
        longitude:localStorage.getItem('longitude'),
        userdevice:localStorage.getItem('userdevice'),
      };
      this.getNumber = this.getNumber.bind(this);
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
    setInterval(this.getNumber, 10000);
    const fetchData = async() =>{
        var sendData = {
          "devicetype":this.state.devicetype,
          "deviceos":this.state.deviceos,
          "devicebrowser":this.state.devicebrowser,
          "latitude":this.state.latitude,
          "longitude":this.state.longitude,
          "userdevice":this.state.userdevice
        }
        return serverCall({
            method:'POST',
            url:`/api/landing/landing-home`,
            data:sendData
          })
          .then(res=>{
            console.log('res.data',res.data);
            this.setState({
                productCount:res.data,
             })
          })
          .catch(error=>{
            console.log('error',error)
            return error
          })
     }
     fetchData();
    this.props.getCategoryData()
  }


  getNumber(e) {
    Auth.getLocation();
    Auth.getDevice();
   var responce =  Auth.getLocationUpdate();
   if(responce === '1'){
    window.reload();
    const fetchData = async() =>{
      var sendData = {
      "devicetype":this.state.devicetype,
      "deviceos":this.state.deviceos,
      "devicebrowser":this.state.devicebrowser,
      "latitude":this.state.latitude,
      "longitude":this.state.longitude,
    }
    return serverCall({
            method:'POST',
            url:`/api/landing/landing-home`,
            data:sendData
          })
          .then(res=>{
            this.setState({
                productCount:res.data,
             })
          })
          .catch(error=>{
            console.log('error',error)
            return error
          })
     }
     fetchData();
    
   }
  }

  handleChangeKeyword = (event) => {
    this.setState({ keyword: event.target.value });
  }  

  handleClick = (subcatID,catVal) => {
    let requestdata= {};
    requestdata.catVal= catVal;
    requestdata.subcatID=subcatID;
    if(catVal === 1){
      this.setState({ category: subcatID })
      this.props.getSubCategoryData(requestdata);
    }else if(catVal === 2){
      this.setState({ subcategoryone: subcatID })
      this.props.getSubCategoryOneData(requestdata);
    }else if(catVal === 3){
      this.setState({ subcategorytwo: subcatID })
      this.props.getSubCategoryTwoData(requestdata);
    }
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => {
    this.setState({ isOpen: false });
  }

// const cacheProvider = {
//  get: (language, key) =>
//    ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
//      language
//    ],
//  set: (language, key, value) => {
//    const existing = JSON.parse(localStorage.getItem('translations')) || {
//      [key]: {},
//    };
//    existing[key] = {...existing[key], [language]: value};
//    localStorage.setItem('translations', JSON.stringify(existing));
//  },
// };

  render() {

    const categoryDetails  = this.props.categoryData;
    const subOneCategoryDetails  = this.props.subcategoryData;
    const subTwoCategoryDetails  = this.props.subonecategoryData;
    const subThreeCategoryDetails  = this.props.subtwocategoryData;
    const categoryData = [];
    const categoryOneData = [];
    const categoryTwoData = [];
    const categoryThreeData = [];

    

    if(categoryDetails !== null && categoryDetails !== undefined){
        
        const categoryListData = categoryDetails.Category;
        

      for(var i = 0; i < categoryListData.length; i++) {
          var categoryDataMain = {};
          categoryDataMain['id'] = categoryListData[i].id;
          categoryDataMain['category_name'] = categoryListData[i].category_name;
          categoryDataMain['Count'] = categoryListData[i].Count;
          categoryData.push(categoryDataMain);
      }
      if(subOneCategoryDetails !== null && subOneCategoryDetails !== undefined && subOneCategoryDetails !== ''){

          const categoryOneListData = subOneCategoryDetails.Category;

          for(var i = 0; i < categoryOneListData.length; i++) {
            var categoryOneDataMain = {};
            categoryOneDataMain['id'] = categoryOneListData[i].id;
            categoryOneDataMain['category_name'] = categoryOneListData[i].subcat1_name;
            categoryOneDataMain['Count'] = categoryOneListData[i].subcatOneCount;
            categoryOneData.push(categoryOneDataMain);
        }
      }

      if(subTwoCategoryDetails !== null && subTwoCategoryDetails !== undefined && subTwoCategoryDetails !== ''){

          const categoryTwoListData = subTwoCategoryDetails.Category;

          for(var i = 0; i < categoryTwoListData.length; i++) {
            var categoryTwoDataMain = {};
            categoryTwoDataMain['id'] = categoryTwoListData[i].id;
            categoryTwoDataMain['category_name'] = categoryTwoListData[i].subcat2_name;
            categoryTwoDataMain['Count'] = categoryTwoListData[i].subcatTwoCount;
            categoryTwoData.push(categoryTwoDataMain);
        }
      }

      if(subThreeCategoryDetails !== null && subThreeCategoryDetails !== undefined && subThreeCategoryDetails !== ''){

          const categoryThreeListData = subThreeCategoryDetails.Category;

          for(var i = 0; i < categoryThreeListData.length; i++) {
            var categoryThreeDataMain = {};
            categoryThreeDataMain['id'] = categoryThreeListData[i].id;
            categoryThreeDataMain['category_name'] = categoryThreeListData[i].subcat3_name;
            categoryThreeDataMain['Count'] = categoryThreeListData[i].subcatThreeCount;
            categoryThreeData.push(categoryThreeDataMain);
        }
      }

    }
    var productCount = this.state.productCount;

    const logocompany = require('../../assets/images/rlogo.png');
    const logocity = require('../../assets/images/store01.png');
    const logoCountCity = require('../../assets/images/shop01.png');

    return (
      <span>
      <HeaderContainer />
          <a onClick={() => jumpTo('/marketplace-home')} className="GBX_logo homeLogo">
            <img src={logocompany}/>
          </a>
           <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >

              <h2 className="shopData homeHead homeContainerHead"><Translate>Find Your Product</Translate></h2>
          <div className="categories_search_bar">
            <div className="container">
              <div className="wht_bx">
                <div className="flex_div">
                  <form className="navbar-form" onSubmit={() => jumpToData('/advance-search/',{keyword:this.state.keyword,name:'keyword',code:this.state.keyword})}>
                    <a className="voice_serch " title="Search by Voice Coming Soon"><i className="fas fa-microphone"></i></a>
                    <input className="form-control" onChange ={this.handleChangeKeyword} placeholder="Search by Keyword . . ." type="text" />
                  </form>
                  <div className="btns_div">
                    <button type="button" onClick={() => jumpToData('/advance-search/',{keyword:this.state.keyword,name:'keyword',code:this.state.keyword})} className="btn blueBtn"><Translate>Products</Translate> <span className="play_icon"><i className="fas fa-play playBtn"></i></span></button>
                    <button type="button" onClick={() => jumpToData('/vendor-result/'+this.state.keyword,{keyword:this.state.keyword,name:'search_data',code:this.state.keyword,search_data:this.state.keyword})} className="btn light_green_btn"><Translate>Suppliers </Translate><span className="play_icon"><i className="fas fa-play playBtn"></i></span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shopby homeBody">
              <div className="HomeContainer">
                  <div className="row margin_7">
                      <div className="col-sm-3 landingBox">
                          <a onClick={() => jumpTo('/landing-shop')} className="shop_box homeBox ">
                              <div className="land_content-box">
                                  <div className="shop-icon"><img src={logoCountCity} /></div>
                                    <h2 className="homeNumber">
                                      {productCount !== null ? productCount.cityCount :''}
                                    </h2>
                                  <span className="shop-border"></span>
                                  <p className="shop-below-title"><Translate>Shop in </Translate><br/>{productCount.city}</p>
                              </div>    
                          </a>
                      </div>
                      <div className="col-sm-3 landingBox">
                          <a onClick={() => jumpTo('/landing-province')} className="shop_box keyword_box homeBox">
                              <div className="land_content-box">
                                  <div className="shop-icon"><i className="fas fa-store"></i></div>
                                   <h2 className="homeNumber">
                                    {productCount !== null ? productCount.StateProductCount :''}
                                   </h2>
                                  <span className="shop-border"></span>
                                  <p className="shop-below-title"><Translate>Shop in </Translate><br/> {productCount !== null || productCount !== '' || productCount !==undefined ? productCount.state :''}</p>
                              </div>
                          </a>
                      </div>
                        <div className="col-sm-3 landingBox">
                          <a href="" className="shop_box avail_box homeBox" onClick={() => jumpTo('/marketplace/'+productCount.countryName)}>
                            <div className="land_content-box">
                              <div className="shop-icon"><i className="fas fa-globe-asia"></i></div>
                              <h2 className="homeNumber">
                                {productCount !== null ? productCount.countryCount :''}
                               </h2>
                              <span className="shop-border"></span>
                              <p className="shop-below-title"><Translate>Available  in </Translate><br/>{productCount !== null && productCount !== '' && productCount !==undefined ? productCount.countryName :''}</p>
                            </div>
                          </a>
                        </div>
                        <div className="col-sm-3 landingBox">
                          <a onClick={() => jumpTo('/landing-deal-promotion')} className="shop_box recomm_box homeBox">
                            <div className="land_content-box">
                              <div className="shop-icon"><i className="far fa-check-circle"></i></div>
                              <h2 className="homeNumber">
                                 {productCount !== null ? productCount.dealCount :''}
                                </h2>
                              <span className="shop-border"></span>
                              <p className="shop-below-title mendations_txt"><Translate>Deals and</Translate><br/><Translate> Promotions</Translate></p>

                            </div>
                          </a>
                        </div>
                  </div>
                  <div className="row margin_7">
                  <div className="col-sm-3 landingBox">
                   <a onClick={() => jumpTo('/advance-search')} className="shop_box adv_box homeBox ">
                      <div className="land_content-box">
                        <div className="shop-icon"><i className="fas fa-search-plus"></i></div>
                        <span className="shop-border"></span>
                        <p className="shop-below-title"><Translate>Advanced</Translate><br/><Translate>Search</Translate></p>
                      </div>
                    </a>
                  </div>      
                  
                  <div className="col-sm-3 landingBox">
                    <a href="#" className="shop_box cate_box toggle-button homeBox" onClick={this.openModal}>
                      <div className="land_content-box">
                        <div className="shop-icon"><i className="fas fa-sitemap"></i></div>
                        <span className="shop-border"></span>
                        <p className="shop-below-title"><Translate>All</Translate><br/><Translate>Categories</Translate></p>
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-3 landingBox">
                    <a onClick={() => jumpTo('/landing-market-segment')} className="shop_box market_box homeBox">
                      <div className="land_content-box">
                        <div className="shop-icon market_sg shop-icon-segment"><img src={logocity} /></div>
                        <span className="shop-border"></span>
                        <p className="shop-below-title"><Translate>Market </Translate><br/><Translate>Segments</Translate></p>
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-3 landingBox">
                    <a onClick={() => jumpTo('/marketplace-home')} className="shop_box home-page_box homeBox">
                      <div className="land_content-box">
                        <div className="shop-icon"><i className="fas fa-home"></i></div>
                        <span className="shop-border"></span>
                        <p className="shop-below-title"><Translate>Home</Translate><br/><Translate> Page</Translate></p>

                      </div>
                    </a>
                  </div>        
                </div>  
              </div>
                <div className="partner_footer_label ">
            <p className="partner_contentCookies"><Translate>OFFICIAL PARTNER OF THE ROMANIAN RUGBY FEDERATION</Translate></p>
            </div>
          </div>
          {/* Home Page Popup  css Strat */}
          <div className="modal fade Categories_Popup" id="myModal" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-content">
                  <div className="modal-header blue_btn">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><i className="far fa-times-circle"></i></button>
                    <h4 className="modal-title withoutuser uprcase"><Translate>All Categories</Translate><span className="icon_spn blue"></span> </h4>
                  </div>
                  <div className="modal-body">
                    <div className="heading_control">
                      <div className="panel_heading">
                        <span className="accordion_txt"><a></a></span>
                        <a className="panel_title"><i className="fas fa-list-ul"></i></a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          

          <Modal className="Categories_Popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
            <Modal.Header className="blue_btn">
                  <button type="button" className="closeBtnData" onClick={this.closeModal}><i className="far fa-times-circle"></i></button>
                      <h4 className="modal-title withoutuser uprcase"><Translate>All Categories</Translate><span className="icon_spn blue"></span> </h4>
            </Modal.Header>
            <Modal.Body>
              <div className="heading_control">
                
                  {categoryData.map(({ id,category_name,Count }, index) => <div key={index} className="panel_heading"><span className="accordion_txt"><a onClick={() => jumpToData('/advance-search/',{category:id,name:'category',code:id})}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg">{Count}</span></span>
                    <a className="panel_title" onClick={() => this.handleClick(id,1)}><i className="fas fa-list-ul"></i></a>
                  </div>)}
                  
              </div>
              <div className="panel_group">
                <div className="panel-body">
                  <div className="row margin_auto">
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 1</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryOneData.map(({ id,category_name,Count }, index) => <div key={index} className="panel_heading"><span className="accordion_txt"><a onClick={() => jumpToData('/advance-search/',{category:this.state.category,name:'category',subcategoryone:id,code:this.state.category})}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg">{Count}</span></span>
                          <a className="panel_title" onClick={() => this.handleClick(id,2)}><i className="fas fa-list-ul"></i></a>
                        </div>)}
                        </div>
                        <div className="shadow_box" ng-if="lamr.subCat ==''">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 1</Translate></span></a>
                        </div>
                      </div>                      
                    </div>
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 2</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryTwoData.map(({ id,category_name,Count }, index) => <div className="panel_heading"><span className="accordion_txt"><a onClick={() => jumpToData('/advance-search/',{category:this.state.category,name:'category',subcategoryone:this.state.subcategoryone,subcategorytwo:id,code:this.state.category})}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg">{Count}</span></span>
                            <a className="panel_title" onClick={() => this.handleClick(id,3)}><i className="fas fa-list-ul"></i></a>
                          </div>)}
                        </div>
                        <div className="shadow_box" ng-if="lamr.subCat1 ==''">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 2</Translate></span></a>
                        </div>
                      </div>                      
                    </div>
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 3</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryThreeData.map(({ id,category_name,Count }, index) => <div className="panel_heading"><span className="accordion_txt"><a onClick={() => jumpToData('/advance-search/',{category:this.state.category,name:'category',subcategoryone:this.state.subcategoryone,subcategorytwo:this.state.subcategoryone,subcategorythree:id,code:this.state.category})}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg">{Count}</span></span>
                            <a></a>
                          </div>)}
                        </div>
                        <div className="shadow_box" ng-if="lamr.subCat2 ==''">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 3</Translate></span>
                          </a>
                        </div>
                      </div>                      
                    </div>          
                  </div>          
                </div>
              </div>

            </Modal.Body>
          </Modal>
            </Translator>
      </span>
    )
  }
}





