import React, { Component } from 'react'
import './stylesheets/landingMarketsegment.css'
import jumpTo,{jumpToData} from '../../modules/Navigation'
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'
import HeaderContainer from '../../components/header/headerContainer'


export default class landingMarketsegment extends Component {
  constructor(props) {
    super(props);
    this.state={
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
      this.props.getProductCountSagment()
  }
  render() {
    const logocompany = require('../../assets/images/d_logo.png');
    const logobusiness = require('../../assets/images/business.png');
    const logoconsumer = require('../../assets/images/consumer.png');
    const logobussgover = require('../../assets/images/buss_gover.png');
    const logomanubusiness = require('../../assets/images/manu_business.png');
    const logomanuconsum = require('../../assets/images/manu_consum.png');
    const logomanugover = require('../../assets/images/manu_gover.png');
    const logogovernment = require('../../assets/images/government.png');
    const logogovbusiness = require('../../assets/images/gov_business.png');
    const logoconsumerto = require('../../assets/images/consumer-to.png');

    return (
      <span>
      <HeaderContainer />
              <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
          <a onClick={() => jumpTo('/marketplace-home')} className="GBX_logo segmentLogo">
            <img src={logocompany}/>
          </a>

          <div className="Filter_heading container pt-0">
            <div className="seg_back text-left intr_pges">
              <a onClick={() => jumpTo('landing-marketplace')} className="land_back segmntBackBtn"><i className="fas fa-arrow-left"></i><span> <Translate>BACK</Translate></span></a>
              <h2 className="shop_head segmentHead"><Translate>Filter by Market Segments</Translate></h2>
            </div>
          </div>

          <div className="shopby blue_bg_main">
            <div className="HomeContainer">
              <div className="market_seg">
                <div className="row margin_7">
                  <div className=" segment_container">
                    <a className="recommend_box busines_bus segment_box" onClick={() => jumpToData('/product-result/B2B',{market_segment:'B2B',name:'Business 2 Business',code:'B2B',condition:1})}>
                        <div className="title_box">B2B</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logobusiness} />
                        </div>
                            <h2 className="numberCity">
                              {this.props.productCount !== null ? this.props.productCount.marketSagment.B2B :''}
                            </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Business</Translate> 2 <Translate>Business</Translate></p> 
                    </a>
                  </div>
                  <div className="col-sm-4 segment_container">
                    <a className="recommend_box busines_con segment_box" onClick={() => jumpToData('/product-result/B2C',{market_segment:'B2C',name:'Business 2 Consumer',code:'B2C',condition:1})}>
                    
                      <div className="recommend_txt">
                        <div className="title_box">B2C</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logoconsumer} />
                        </div>
                            <h2 className="numberCity">
                              {this.props.productCount !== null ? this.props.productCount.marketSagment.B2C :''}
                            </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Business 2 Consumer</Translate></p>
                      </div>  
                    </a>
                  </div>
                  <div className="col-sm-4 segment_container">
                    <a className="recommend_box bus_gov1 segment_box" onClick={() => jumpToData('/product-result/B2G',{market_segment:'B2G',name:'Business 2 Government',code:'B2G',condition:1})}>
                      <div className="recommend_txt">
                        <div className="title_box">B2G</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logobussgover} />
                        </div>
                          <h2 className="numberCity">
                             {this.props.productCount !== null ? this.props.productCount.marketSagment.B2G :''}
                          </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Business 2 Government</Translate></p>
                      </div>  
                    </a>
                  </div>
                  <div className="col-sm-4 segment_container">
                    <a className="recommend_box man_bus segment_box" onClick={() => jumpToData('/product-result/M2B',{market_segment:'M2B',name:'Manufacturer 2 Business',code:'M2B',condition:1})}>
                      <div className="recommend_txt">
                        <div className="title_box">M2B</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logomanubusiness} />
                        </div>
                         <h2 className="numberCity">
                          {this.props.productCount !== null ? this.props.productCount.marketSagment.M2B :''}
                         </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Manufacturer 2 Business</Translate></p>
                      </div>  
                    </a>
                  </div>
                  <div className="col-sm-4 segment_container">
                    <a className="recommend_box man_con segment_box" onClick={() => jumpToData('/product-result/M2C',{market_segment:'M2C',name:'Manufacturer 2 Consumer',code:'M2C',condition:1})}>
                      <div className="recommend_txt">
                        <div className="title_box">M2C</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logomanuconsum} />
                        </div>
                           <h2 className="numberCity">
                            {this.props.productCount !== null ? this.props.productCount.marketSagment.M2C :''}
                           </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Manufacturer 2 Consumer</Translate></p>
                      </div>  
                    </a>
                  </div>
                  <div className="col-sm-4 segment_container">
                    <a className="recommend_box man_gov segment_box" onClick={() => jumpToData('/product-result/M2G',{market_segment:'M2G',name:'Manufacturer 2 Government',code:'M2G',condition:1})}>
                      <div className="recommend_txt">
                        <div className="title_box">M2G</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logomanugover} />
                        </div>
                         <h2 className="numberCity">
                          {this.props.productCount !== null ? this.props.productCount.marketSagment.M2G :''}
                         </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Manufacturer 2 Government</Translate></p>
                      </div>  
                    </a>
                  </div>
                  <div className="col-sm-4 segment_container">
                    <a className="recommend_box gov_gov segment_box" onClick={() => jumpToData('/product-result/G2G',{market_segment:'G2G',name:'Government 2 Government',code:'G2G',condition:1})}>
                      <div className="recommend_txt">
                        <div className="title_box">G2G</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logogovernment} />
                        </div>
                          <h2 className="numberCity">
                           {this.props.productCount !== null ? this.props.productCount.marketSagment.G2G :''}
                          </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Government 2 Government</Translate></p>
                      </div>  
                    </a>
                  </div>  
                  <div className="col-sm-4 segment_container">
                    <a className="recommend_box gov_bus segment_box" onClick={() => jumpToData('/product-result/G2B',{market_segment:'G2B',name:'Government 2 Business',code:'G2B',condition:1})}>
                      <div className="recommend_txt">
                        <div className="title_box">G2B</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logogovbusiness} />
                        </div>
                          <h2 className="numberCity">
                           {this.props.productCount !== null ? this.props.productCount.marketSagment.G2B :''}
                          </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Government 2 Business</Translate></p>
                      </div>  
                    </a>
                  </div>
                  <div className="col-sm-4 segment_container">
                    <a className="recommend_box con_con segment_box" onClick={() => jumpToData('/product-result/C2C',{market_segment:'C2C',name:'Consumer 2 Consumer',code:'C2C',condition:1})}>   
                      <div className="recommend_txt">
                        <div className="title_box">C2C</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logoconsumerto} />
                        </div>
                          <h2 className="numberCity">
                           {this.props.productCount !== null ? this.props.productCount.marketSagment.C2C :''}
                          </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Consumer 2 Consumer</Translate></p>
                      </div>  
                    </a>
                  </div>
                  <div className="col-sm-4 segment_container">
                    <a title="coming soon" className="recommend_box con_con segment_box">
                      <div className="recommend_txt">
                        <div className="title_box">F2C</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logoconsumer} />
                        </div>  
                          <h2 className="numberCity">
                          --
                          </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Farmer 2 Consumer</Translate></p>
                      </div>  
                    </a>
                  </div>
                  <div className="col-sm-4 segment_container">
                    <a title="coming soon" className="recommend_box con_con segment_box">   
                      <div className="recommend_txt">
                        <div className="title_box">F2B</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logoconsumer} />
                        </div>
                          <h2 className="numberCity">
                          --
                          </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Farmer 2 Business</Translate></p>
                      </div>  
                    </a>
                  </div>
                  <div className="col-sm-4 segment_container">
                   <a title="coming soon"className="recommend_box con_con segment_box">
                      <div className="recommend_txt">
                        <div className="title_box">F2G</div>
                        <div className="recommend-icon segmnt-icon">
                          <img src={logoconsumer} />
                        </div>
                          <h2 className="numberCity">
                          --
                          </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title segmnt_blw"><Translate>Farmer 2 Government</Translate></p>
                      </div>  
                    </a>
                  </div>
              </div>  
            </div>
          </div>
        </div>
        <div className="partner_footer_label ">
            <p className="partner_contentCookies"><Translate>OFFICIAL PARTNER OF THE ROMANIAN RUGBY FEDERATION</Translate></p>
        </div>
        </Translator>
      </span>
    )
  }
}





