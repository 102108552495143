import React, { Component } from 'react'
import './stylesheets/mainMenu.css'
import jumpTo,{jumpToData} from '../../modules/Navigation'
import {isMobile} from 'react-device-detect'
import serverUrl from '../../configs/constant'
import {Translator, Translate} from 'react-auto-translate';
export default class MainMenu extends Component {
  // const isLoggedin = 0;

  constructor(props) {
      super(props)
      let requestdata = window.location.pathname.split("/");
      let requestdataMain = requestdata[1];
      let requestdataNew = {};
      requestdataNew.userdata = localStorage.getItem('userData');
      requestdataNew.latitude = localStorage.getItem('latitude');
      requestdataNew.longitude = localStorage.getItem('longitude');
      requestdataNew.devicebrowser = localStorage.getItem('devicebrowser');
      requestdataNew.deviceos = localStorage.getItem('deviceos');
      requestdataNew.devicetype = localStorage.getItem('devicetype');
      requestdataNew.userdevice = localStorage.getItem('userdevice');
      this.state = {
        menu: false,
        isTop: true,
        isMobileTop: true,
        homebtn: requestdataMain,
        userData: localStorage.getItem('userData'),
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
      };
      this.props.getMenuData(requestdataNew);
      this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
      
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 460;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
      const isMobileTop = window.scrollY < 42;
      if (isMobileTop !== this.state.isMobileTop) {
          this.setState({ isMobileTop })
      }
    });
  }

  toggleMenu(){
    this.setState({ menu: !this.state.menu })
  }


  render() {

    let DiscountedProducts = '';
    let FeaturedProducts = '';
    let city = '';
    let ProductCountCity = '';
    let VendorCity = '';
    let postal = '';
    let ProductsPostal = '';
    let VendorPostal = '';
    let country = '';
    let countryCode = '';
    let countryImg = '';
    let ProductCountcountry = '';
    let MegaBrandsCount = '';
    let buyerCurrency = '';
    let shoppingCartCount = '';
    let shoppingCartAmount = '';
    let shopurl = '#';
    if(this.state.userData !=='' && this.state.userData !== undefined){
      shopurl = 'https://'+serverUrl.serverdomain+'/backend/shoppingcart-product';
    }
    let logocompany = require('../../assets/images/rlogo.png');
    let menuDetails  = this.props.menuData;
    if(menuDetails !== null && menuDetails !== undefined){
       DiscountedProducts = menuDetails.DiscountedProductCount;
       FeaturedProducts = menuDetails.FeaturedProductCount;
       city = menuDetails.city;
       ProductCountCity = menuDetails.CityProductCount;
       VendorCity = menuDetails.CityVendorCount;
       postal = menuDetails.postal;
       ProductsPostal = menuDetails.PostalCodeProductCount;
       VendorPostal = menuDetails.PostalCodeVendorCount;
       country = menuDetails.country_name;
       countryCode = menuDetails.country_code;
       countryImg = 'https://'+serverUrl.serverdomain+'/backend/public/img/flags/'+menuDetails.country_code+'.png';
       ProductCountcountry = menuDetails.ProductAvailableCount;
       MegaBrandsCount = menuDetails.MegaBrandsCount;
       buyerCurrency = menuDetails.buyer_currency;
       shoppingCartCount = menuDetails.shoppingCartCount;
       shoppingCartAmount = menuDetails.shoppingCartAmount;
    }
    

    const show = (this.state.menu) ? "show" : "" ;

    return (
      <span>
      <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
      {isMobile == false ? 
        <div className={this.state.isTop ? 'main_header mrgn_none' : 'header_fixed main_header mrgn_none'}>
          <header>
            <div className="container">
              <div className="row">
                <div className="col-sm-2 d-none d-md-block">
                  <div className="logo_box">
                    <a onClick={() => jumpTo('/landing-marketplace')} title="logo">
                      <img src={logocompany} title="" alt="logo"/>                
                    </a>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                  <nav className="">
                    <div className="navbar-header">
            <div className="row d-flex d-md-none justify-content-between">
              <div className="col">
                <div className="logo_box">
                  <a onClick={() => jumpTo('/landing-marketplace')} title="logo">
                    <img src={logocompany} title="" alt="logo"/>                
                  </a>
                </div>
              </div>
              <div className="col text-center" >
                <span className="drop_Box" onClick={() => jumpTo('/advance-search')} ><a ui-sref="advanceSearch" className="menu_header">Advanced Search</a></span>
              </div>
              <div className="col text-end">
                <a onClick={() => jumpTo('/marketplace-home')} className="home_box">
                  <i className={this.state.homebtn ==='marketplace-home'? "active fas fa-home":"fas fa-home"}></i>
                </a>
              </div>
            </div>
          </div>
                      <div className="collapse navbar-collapse Example_Nav2" id="bs-example-navbar-collapse-2">
                        <ul className="list-inline">
                          <li className="dealsLine">
                            <a onClick={() => jumpTo('/marketplace-home')} className="home_box">
                              <i className={this.state.homebtn ==='marketplace-home'? "active fas fa-home":"fas fa-home"}></i>
                            </a>
                          </li>                                      
                          <li  className={this.state.homebtn ==='deals-promotion' || this.state.homebtn ==='featured-list'? "active dropdown dropdown_box drop_Box recommend_drop":"dropdown dropdown_box drop_Box recommend_drop"}>
                            <a href="#" className="dropdown-toggle " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><Translate>Deals and</Translate><br/><Translate>Promotions</Translate></a>
                            <ul className="dropdown-menu Mobile_menu Sub_menu">
                            <li className=""><a onClick={() => jumpTo('/deals-promotion')} className="tooltip_box left_toolbox dealsDropDown"><Translate>DISCOUNTED PRODUCTS</Translate><span className="tooltiptext_box"><h5>{DiscountedProducts}</h5><p><Translate>Total Discounted Products</Translate></p>
                              </span></a></li>
                            <li className=""><a onClick={() => jumpTo('/featured-list')} className="tooltip_box left_toolbox dealsDropDown"><Translate>FEATURED PRODUCTS</Translate><span className="tooltiptext_box"><h5>{FeaturedProducts}</h5><p><Translate>Total Featured Products</Translate></p>
                              </span></a></li>
                            <li><a title="Coming Soon"><Translate>MEGA BRANDS</Translate></a></li> 
                            </ul>
                          </li> 
                          <div className="advancesearchmobile">
                          <li className={this.state.homebtn ==='advance-search'? "active drop_Box d-none d-md-block":"drop_Box d-none d-md-block"} onClick={() => jumpTo('/advance-search')} ><a ui-sref="advanceSearch"><Translate>Advanced</Translate><br/><Translate>Search</Translate></a></li>
                          </div>
                        </ul>
                      </div>
                  </nav>
                </div>
                  <div className="col-sm-4 col-xs-12 text-right">
                    <div className="right_user">
                      <ul className="nav">
                        <li className="dropdown">
                          <a className="dropdown-toggle" data-toggle="dropdown" onClick={ this.toggleMenu }><span className="user_img activeTab"><b><Translate>Shop In:</Translate></b></span> <span className="user_name"><Translate>{city}</Translate></span> <span className="caret"></span></a>
                          <ul className={"dropdown-menu Sub_menu " + show} onClick={ this.toggleMenu }>
                          <li><a className="tooltip_box left_toolbox" onClick={() => jumpToData('/product-result/'+city,{cityname:city,name:city,code:countryCode})}><b>Products LOCATED in {city}</b>
                          <span className="tooltiptext_box"><h5>{ProductCountCity}</h5><p><b>Products LOCATED in<br/>{city}</b></p>
                              </span></a></li>
                          <li><a className="tooltip_box left_toolbox" onClick={() => jumpToData('/vendor-result/'+city,{cityname:city,name:city,code:countryCode})}><b>Vendors LOCATED in {city }</b> <span className="tooltiptext_box"><h5><Translate>{VendorCity}</Translate></h5><p><b><Translate>Vendors LOCATED in</Translate><br/><Translate>{city}</Translate></b></p>
                              </span></a></li>
                          <li><a className="tooltip_box left_toolbox"><b>Shopping Centres in {city}</b></a></li>
                          <li><a className="tooltip_box left_toolbox"><b>Markets LOCATED  in {city}</b></a></li>        
                          <li><a className="tooltip_box left_toolbox" onClick={() => jumpToData('/product-result/'+postal,{postalname:postal,name:postal,code:countryCode})}><b><Translate>Products LOCATED in </Translate>&nbsp;{postal}</b> <span className="tooltiptext_box"><h5>{ProductsPostal}</h5><p><b>Products LOCATED in<br/>{postal}</b></p>
                              </span></a></li>        
                          <li><a className="tooltip_box left_toolbox" onClick={() => jumpToData('/vendor-result/'+postal,{postalname:postal,name:postal,code:countryCode})}><b><Translate>Vendors LOCATED in </Translate>&nbsp;{postal}</b> <span className="tooltiptext_box"><h5>{VendorPostal}</h5><p><b><Translate>Vendors LOCATED in</Translate><br/>{postal}</b></p>
                              </span></a></li>        
                          </ul>
                        </li>
                        <li className="dropdown">
                          <a className="dropdown-toggle" onClick={() => jumpTo('/marketplace/'+country)}>
                          <span className="user_img activeTab"><b><Translate>Products AVAILABLE in:</Translate></b></span> 
                          <span className="user_name tooltip tooltip_box">
                              {country}<img src={countryImg} title="" alt={country}/>
                              {ProductCountcountry !== 0 && ProductCountcountry !== '' ?
                            <span className="tooltiptext tooltiptext_box"><h5>{ProductCountcountry}</h5><p><Translate>Products AVAILABLE in</Translate></p><Translate>{country}</Translate></span>
                            : <span className="tooltiptext tooltiptext_box"><p><Translate>Sorry, no items are currently available in</Translate></p><Translate>{country}</Translate></span>}</span> 
                          </a>
                        </li> 
                      </ul>                                 
                    </div>            
                </div>
              </div>
            </div>
          </header>
        </div>
        : <div className={this.state.isMobileTop ? 'main_header mrgn_none' : 'header_fixed main_header mrgn_none'}>
          <header>
            <div className="container">
              <div className="row MainMenuBarRow">
                <div className="col-sm-2 d-none d-md-block">
                  <div className="logo_box">
                    <a onClick={() => jumpTo('/landing-marketplace')} title="logo">
                      <img src={logocompany} title="" alt="logo"/>                
                    </a>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                  <nav className="">
                    <div className="navbar-header">
            <div className="row d-flex d-md-none justify-content-between headerGutter-x">
              <div className="col">
                <div className="logo_box">
                  <a onClick={() => jumpTo('/landing-marketplace')} title="logo">
                    <img src={logocompany} title="" alt="logo"/>                
                  </a>
                </div>
              </div>
              <div className="col text-center" >
                <span className="drop_Box" onClick={() => jumpTo('/advance-search')} ><a ui-sref="advanceSearch" className="menu_header"><Translate>Advanced Search</Translate></a></span>
              </div>
              <div className="col text-end">
                <a onClick={() => jumpTo('/marketplace-home')} className="home_box">
                  <i className={this.state.homebtn ==='marketplace-home'? "active fas fa-home":"fas fa-home"}></i>
                </a>
              </div>
            </div>
          </div>
                      <div className="collapse navbar-collapse Example_Nav2" id="bs-example-navbar-collapse-2">
                        <ul className="list-inline">
                          <li>
                            <a onClick={() => jumpTo('/marketplace-home')} className="home_box">
                              <i className={this.state.homebtn ==='marketplace-home'? "active fas fa-home":"fas fa-home"}></i>
                            </a>
                          </li> 
                                                       
                          <li  className={this.state.homebtn ==='deals-promotion' || this.state.homebtn ==='featured-list'? "active dropdown dropdown_box drop_Box recommend_drop":"dropdown dropdown_box drop_Box recommend_drop"}>
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><Translate>Deals and Promotions</Translate></a>
                            <ul className="dropdown-menu Mobile_menu Sub_menu">
                            <li className=""><a onClick={() => jumpTo('/deals-promotion')} className="tooltip_box left_toolbox "><Translate>Discounted Products</Translate><span className="tooltiptext_box"><h5>{DiscountedProducts}</h5><p><Translate>Total Discounted Products</Translate></p>
                              </span></a></li>
                            <li className=""><a onClick={() => jumpTo('/featured-list')} className="tooltip_box left_toolbox"><Translate>Featured Products</Translate><span className="tooltiptext_box"><h5>{FeaturedProducts}</h5><p><Translate>Total Featured Products</Translate></p>
                              </span></a></li>
                            <li><a title="Coming Soon"><Translate>Mega Brands</Translate></a></li>               
                            </ul>
                          </li> 
                          <div className="advancesearchmobile">
                          <li className={this.state.homebtn ==='advance-search'? "active drop_Box d-none d-md-block":"drop_Box d-none d-md-block"} onClick={() => jumpTo('/advance-search')} ><a ui-sref="advanceSearch"><Translate>Advanced</Translate><br/><Translate>Search</Translate></a></li>
                          </div>
                        </ul>
                      </div>
                  </nav>
                </div>
                  <div className="col-sm-4 col-xs-12 text-right">
                    <div className="right_user">
                      <ul className="nav">
                        <li className="dropdown">
                          <a className="dropdown-toggle" data-toggle="dropdown" onClick={ this.toggleMenu }><span className="user_img activeTab"><b>Shop In:</b></span> <span className="user_name">{city}</span> <span className="caret"></span></a>
                          <ul className={"dropdown-menu Sub_menu " + show} onClick={ this.toggleMenu }>
                          <li><a className="tooltip_box left_toolbox" onClick={() => jumpToData('/product-result/'+city,{cityname:city,name:city,code:countryCode})}><b><Translate>Products LOCATED in {city}</Translate></b>
                          <span className="tooltiptext_box"><h5>{ProductCountCity}</h5><p><b><Translate>Products LOCATED in</Translate><br/><Translate>{city}</Translate></b></p>
                              </span></a></li>
                          <li><a className="tooltip_box left_toolbox" onClick={() => jumpToData('/vendor-result/'+city,{cityname:city,name:city,code:countryCode})}><b><Translate>Vendors LOCATED in {city }</Translate></b> <span className="tooltiptext_box"><h5><Translate>{VendorCity}</Translate></h5><p><b><Translate>Vendors LOCATED in</Translate><br/><Translate>{city}</Translate></b></p>
                              </span></a></li>
                          <li><a className="tooltip_box left_toolbox"><b><Translate>Shopping Centres in {city}</Translate></b></a></li>        
                          <li><a className="tooltip_box left_toolbox" onClick={() => jumpToData('/product-result/'+postal,{postalname:postal,name:postal,code:countryCode})}><b>Products LOCATED in {postal}</b> <span className="tooltiptext_box"><h5>{ProductsPostal}</h5><p><b>Products LOCATED in<br/>{postal}</b></p>
                              </span></a></li>        
                          <li><a className="tooltip_box left_toolbox" onClick={() => jumpToData('/vendor-result/'+postal,{postalname:postal,name:postal,code:countryCode})}><b>Vendors LOCATED in {postal}</b> <span className="tooltiptext_box"><h5>{VendorPostal}</h5><p><b>Vendors LOCATED in<br/>{postal}</b></p>
                              </span></a></li>        
                          </ul>
                        </li>
                        <li className="dropdown">
                          <a className="dropdown-toggle" onClick={() => jumpTo('/marketplace/'+country)}>
                          <span className="user_img activeTab"><b>Products AVAILABLE in:</b></span> 
                          <span className="user_name tooltip tooltip_box">
                            {country}<img src={countryImg} title="" alt={country}/>
                            {ProductCountcountry !== 0 && ProductCountcountry !== '' ?
                            <span className="tooltiptext tooltiptext_box"><h5>{ProductCountcountry}</h5><p>Products AVAILABLE in</p>{country}</span>
                            : <span className="tooltiptext tooltiptext_box"><p>Sorry, no items are currently available in</p>{country}</span>}</span> 
                          </a>
                        </li> 
                      </ul>                                 
                    </div>            
                </div>
              </div>
            </div>
          </header>

        </div> }
        
         <div className="Shopping_Cart">
          <div className="Cart_box">
            <a href={shopurl} title="click here for Shopping Cart" className="blue_btn">
              <div className="First_div">
                <i className="fas fa-shopping-cart"></i>
                <span className="noti_spn transition">{shoppingCartCount}</span>
              </div>
              <div className="last_div">
                <p>Shopping Cart</p>
                <h3><span>{buyerCurrency}</span> {shoppingCartAmount}</h3>
                {/*<span>
                <p>Time Remaining</p>
                <h3>90:00</h3>
                </span>*/}
              </div>
            </a>
          </div>
        </div>
        </Translator>
      </span>
    )
  }
}