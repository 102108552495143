import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import MainMenuContainer from '../../components/mainMenu/MainMenuContainer'
import './stylesheets/advanceSearch.css'
import {isMobile} from 'react-device-detect';
import jumpTo,{go} from '../../modules/Navigation'
import GoogleMapReact from 'google-map-react'
import ProductGrid from '../marketplaceHome/components/ProductGrid'
import SlideToggle from "react-slide-toggle";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'


export default class advanceSearch extends Component {


  constructor(props) {
    super(props);
    var keywordData = ''; 
    var categoryData = ''; 
    var subcategoryoneData = ''; 
    var subcategorytwoData = ''; 
    var subcategorythreeData = ''; 
    var vendorratingData = '';
    var productratingData = '';
    var marketsegmenttype = [];
    var typeproduct = [];
    var shippingoptiontype = [];
    var latitude = localStorage.getItem('latitude');
    var longitude = localStorage.getItem('longitude');
    var devicebrowser = localStorage.getItem('devicebrowser');
    var deviceos = localStorage.getItem('deviceos');
    var devicetype = localStorage.getItem('devicetype');
    var userdevice = localStorage.getItem('userdevice');
    if(props.location.state !=='' && props.location.state !==undefined){
      if(props.location.state.name === 'keyword'){
        var CollpesBtn = 'COLLAPSE';
        var collapsedClass = 'collapsed';
        // this.setState({ toggleEvent: Date.now(),CollpesBtn:CollpesBtn });
        keywordData= props.location.state.code;
        let requestdata = props.location.state;

        requestdata.typeproduct = typeproduct;
        requestdata.devicetype= devicetype;
        requestdata.deviceos= deviceos;
        requestdata.devicebrowser= devicebrowser;
        requestdata.latitude= latitude;
        requestdata.longitude= longitude;
        requestdata.userdevice= userdevice;
        this.props.getAdvanceSearch(requestdata);
      }else{
        var CollpesBtn = 'COLLAPSE';
        var collapsedClass = 'collapsed';
        // this.setState({ toggleEvent: Date.now(),CollpesBtn:CollpesBtn });
        var categoryData = props.location.state.code; 
        if(props.location.state.subcategoryone !=='' && props.location.state.subcategoryone !==undefined){
          var subcategoryoneData = props.location.state.subcategoryone; 
        }else if(props.location.state.subcategorytwo !=='' && props.location.state.subcategorytwo !==undefined){
          var subcategorytwoData = props.location.state.subcategorytwo; 
        }else if(props.location.state.subcategorythree !=='' && props.location.state.subcategorythree !==undefined){
          var subcategorythreeData = props.location.state.subcategorythree; 
        }
        let requestdata = props.location.state;
        requestdata.typeproduct = typeproduct;
        requestdata.devicetype= devicetype;
        requestdata.deviceos= deviceos;
        requestdata.devicebrowser= devicebrowser;
        requestdata.latitude= latitude;
        requestdata.longitude= longitude;
        requestdata.userdevice= userdevice;
        this.props.getAdvanceSearch(requestdata);
      }
    }else{
        var CollpesBtn = 'COLLAPSE';
        var collapsedClass = '';
    }
    this.state = {
      userdata : localStorage.getItem('userData'),
      isTop: true,
      clicksearch:0,
      bestselling:'',
      mostwishlisted:'',
      lastdays:'',
      lasthours:'',
      devicetype:devicetype,
      deviceos:deviceos,
      devicebrowser:devicebrowser,
      latitude:latitude,
      longitude:longitude,
      keyword:keywordData,
      resultkeyword:keywordData,
      typeproduct:[],
      typeship:[],
      typemarket:[],
      marketsegmenttype:[],
      productoptiontype:[],
      shippingoptiontype:[],
      city:"",
      citycountry:"",
      vendor:"",
      productid:"",
      radiuscity:"",
      radiuscitycountry:"",
      radiuspostalcode:"",
      radiusUnit:"",
      radiuswithin:"",
      category:categoryData,
      subcategoryone:subcategoryoneData,
      subcategorytwo:subcategorytwoData,
      subcategorythree:subcategorythreeData,
      marketsegments:"",
      productoptionsnew:"",
      productOptionsgenuine:"",
      productOptionsgenuinedata:"",
      productOptionsreplicadata:"",
      productOptionsNewdata:"",
      productOptionsUseddata:"",
      productOptionsRefurbisheddata:"",
      volumediscount:"",
      volumediscountdata:"",
      age:"",
      shippingoptions:"",
      shippingoptionsdata:"",
      pickupavailable:"",
      pickupavailabledata:"",
      productscountry:"",
      pricingmax:"",
      pricingmin:"",
      productrating:productratingData,
      vendorrating:vendorratingData,
      datepostedafter:"",
      datepostedbefore:"",
      lastdaysclass:"",
      lasthoursclass:"",
      mostwishlistedclass:"",
      bestsellingclass:"",
      bestSelling:[],
      mostWishlisted:[],
      mainclass:"active",
      limit:12,
      offset:0,
      offsetDataMain:1,
      CollpesBtn:CollpesBtn,
      toggleEvent:0,
      collapsedClass:collapsedClass,
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    };
  }


  onToggle = () => {
    if(this.state.CollpesBtn === 'EXPAND'){
      var CollpesBtn = 'COLLAPSE';
    }else{
      var CollpesBtn = 'EXPAND';
    }
    this.setState({ toggleEvent: Date.now(),CollpesBtn:CollpesBtn,lastdaysclass:'',lasthoursclass:'',mostwishlistedclass:'',bestsellingclass:'',mainclass:'active' });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if(this.state.collapsedClass !==''){
      this.onToggle()
    }
    var latitude = localStorage.getItem('latitude');
    var longitude = localStorage.getItem('longitude');
    var devicebrowser = localStorage.getItem('devicebrowser');
    var deviceos = localStorage.getItem('deviceos');
    var devicetype = localStorage.getItem('devicetype');
    var userdevice = localStorage.getItem('userdevice');
    var sendData = {
      "devicetype":devicetype,
      "deviceos":deviceos,
      "devicebrowser":devicebrowser,
      "latitude":latitude,
      "longitude":longitude,
      "longitude":longitude,
      "userdevice":userdevice
    }
    this.props.getCountryList(sendData);
    this.props.getCategoryData();
     document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 530;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
    });
  }
  componentWillUnmount() {
    let requestdata ={};
    requestdata.offset ='';
    requestdata.offsetclean = 1;
    requestdata.devicetype= this.state.devicetype;
    requestdata.deviceos= this.state.deviceos;
    requestdata.devicebrowser= this.state.devicebrowser;
    requestdata.latitude= this.state.latitude;
    requestdata.longitude= this.state.longitude;
    requestdata.userdevice= this.state.userdevice;
    this.setState({ offsetDataMain:''});
    this.props.getAdvanceSearch(requestdata);
  }


  handleInputChange = event => {

    event.persist();
     if(event.target.checked === true){
      if(this.state.typeproduct.length > 0 && event.target.getAttribute("data") !==null){
        if(event.target.getAttribute("data") === 'Volume Discount'){
          this.setState({
            volumediscountdata:1
          });
        }else if(event.target.getAttribute("data") === 'Genuine'){
          this.setState({
            productOptionsgenuinedata:1
          });
        }else if(event.target.getAttribute("data") === 'Replica'){
          this.setState({
            productOptionsreplicadata:0
          });
        }else if(event.target.getAttribute("data") === 'New'){
          this.setState({
            productOptionsNewdata:1
          });
        }else if(event.target.getAttribute("data") === 'Used'){
          this.setState({
            productOptionsUseddata:0
          });
        }else if(event.target.getAttribute("data") === 'Refurbished'){
          this.setState({
            productOptionsRefurbisheddata:2
          });
        }
        this.state.productoptiontype.push(',');
        this.state.productoptiontype.push(event.target.getAttribute("data"));
        this.state.typeproduct.push(event.target.getAttribute("data"));
      }else if(event.target.getAttribute("data") !==null){
        if(event.target.getAttribute("data") === 'Volume Discount'){
          this.setState({
            volumediscountdata:1
          });
        }else if(event.target.getAttribute("data") === 'Genuine'){
          this.setState({
            productOptionsgenuinedata:1
          });
        }else if(event.target.getAttribute("data") === 'Replica'){
          this.setState({
            productOptionsreplicadata:0
          });
        }else if(event.target.getAttribute("data") === 'New'){
          this.setState({
            productOptionsNewdata:1
          });
        }else if(event.target.getAttribute("data") === 'Used'){
          this.setState({
            productOptionsUseddata:0
          });
        }else if(event.target.getAttribute("data") === 'Refurbished'){
          this.setState({
            productOptionsRefurbisheddata:2
          });
        }
        this.state.productoptiontype.push(event.target.getAttribute("data"));     
        this.state.typeproduct.push(event.target.getAttribute("data"));     
    }
      }else if (event.target.checked === false){
        if(event.target.getAttribute("data") === 'Volume Discount'){
          this.setState({
            volumediscountdata:''
          });
        }else if(event.target.getAttribute("data") === 'Genuine'){
          this.setState({
            productOptionsgenuinedata:''
          });
        }else if(event.target.getAttribute("data") === 'Replica'){
          this.setState({
            productOptionsreplicadata:''
          });
        }else if(event.target.getAttribute("data") === 'New'){
          this.setState({
            productOptionsNewdata:''
          });
        }else if(event.target.getAttribute("data") === 'Used'){
          this.setState({
            productOptionsUseddata:''
          });
        }else if(event.target.getAttribute("data") === 'Refurbished'){
          this.setState({
            productOptionsRefurbisheddata:''
          });
        }
       this.state.productoptiontype.pop(',');
       this.state.productoptiontype.pop(event.target.getAttribute("data"));
       this.state.typeproduct.pop(event.target.getAttribute("data"));

    }

    if(event.target.checked === true){
      if(this.state.typeship.length > 0 && event.target.getAttribute("data-one") !==null){
        if(event.target.getAttribute("data-one") === 'Shipping Required'){
          this.setState({
            shippingoptionsdata:1
          });
        }else if(event.target.getAttribute("data-one") === 'Available for Pickup'){
          this.setState({
            pickupavailabledata:1
          });
        }else if(event.target.getAttribute("data-one") === 'Download'){
          this.setState({
            shippingoptionsdata:0
          });
        }
        this.state.shippingoptiontype.push(',');
        this.state.shippingoptiontype.push(event.target.getAttribute("data-one"));      
        this.state.typeship.push(event.target.getAttribute("data-one"));      
      }else if(event.target.getAttribute("data-one") !==null){
        if(event.target.getAttribute("data-one") === 'Shipping Required'){
          this.setState({
            shippingoptionsdata:1
          });
        }else if(event.target.getAttribute("data-one") === 'Available for Pickup'){
          this.setState({
            pickupavailabledata:1
          });
        }else if(event.target.getAttribute("data-one") === 'Download'){
          this.setState({
            shippingoptionsdata:0
          });
        }
        this.state.shippingoptiontype.push(event.target.getAttribute("data-one"));
        this.state.typeship.push(event.target.getAttribute("data-one"));
    }
      }else if (event.target.checked === false){
        if(event.target.getAttribute("data-one") === 'Shipping Required'){
          this.setState({
            shippingoptionsdata:''
          });
        }else if(event.target.getAttribute("data-one") === 'Available for Pickup'){
          this.setState({
            pickupavailabledata:''
          });
        }else if(event.target.getAttribute("data-one") === 'Download'){
          this.setState({
            shippingoptionsdata:''
          });
        }
        this.state.shippingoptiontype.pop(',');
        this.state.shippingoptiontype.pop(event.target.getAttribute("data-one"));
        this.state.typeship.pop(event.target.getAttribute("data-one"));

    }
     
    if(event.target.checked === true){
        if(this.state.typemarket.length > 0 && event.target.getAttribute("data-two") !==null){
          this.state.markeysegmenttype.push(',');
          this.state.markeysegmenttype.push(event.target.getAttribute("data-two"));
          this.state.typemarket.push(event.target.getAttribute("data-two"));
        }else if(event.target.getAttribute("data-two") !==null){
          this.state.typemarket.push(event.target.getAttribute("data-two"));
          this.state.markeysegmenttype.push(event.target.getAttribute("data-two"));
        }else if (event.target.checked === false){
           this.state.markeysegmenttype.pop(',');
           this.state.markeysegmenttype.pop(event.target.getAttribute("data-two"));
           this.state.typemarket.pop(event.target.getAttribute("data-two"));
        }
      }

      if(event.target.selectedIndex){
       var productratingDatatext = event.target[event.target.selectedIndex].text;
      }else if(this.state.productrating !== ''){
       var productratingDatatext = this.state.productratingNamedata;     
      }

      this.setState({
        [event.target.name]: event.target.value,
        // subcategorythreeNamedata: subcategorythreeDatatext,
        productratingNamedata: productratingDatatext,
        // productoptiontype:this.state.typeproduct,
        // shippingoptiontype:this.state.typeship,
        // markeysegmenttype:this.state.typemarket,

      });
     
  }

  handleInputChangeApi = event => {
    var data = event.value.structured_formatting.main_text;
    this.setState({ city:data});
  }

  handleFormSubmit = event => {
    var CollpesBtn = 'EXPAND';
    this.setState({ toggleEvent: Date.now(),CollpesBtn:CollpesBtn });
    event.preventDefault();
    this.props.getAdvanceSearch(this.state);
    // const validation = this.validator.validate(this.state);
    // this.setState({
    //   validation
    // });
  }

  handleFormSubmitClick = event => {
    var CollpesBtn = 'EXPAND';
    // this.setState({ toggleEvent: Date.now(),CollpesBtn:CollpesBtn });
    event.preventDefault();
    this.state.keyword = this.state.resultkeyword;
    this.props.getAdvanceSearch(this.state);
    // const validation = this.validator.validate(this.state);
    // this.setState({
    //   validation
    // });
  }

  handleClickKeyword = (e) => {
    var CollpesBtn = 'EXPAND';
    this.setState({ toggleEvent: Date.now(),CollpesBtn:CollpesBtn });
      e.preventDefault()
      this.props.getAdvanceSearch(this.state);
  }

  handleClick= event => {
    var CollpesBtn = 'EXPAND';
    this.setState({ toggleEvent: Date.now(),CollpesBtn:CollpesBtn });
    event.preventDefault();
    this.props.getAdvanceSearch(this.state);
    // const validation = this.validator.validate(this.state);
    // this.setState({
    //   validation
    // });
  }

  handleReset= event => {
    window.location.reload(false);
    var CollpesBtn = 'EXPAND';
    this.setState({ toggleEvent: Date.now(),CollpesBtn:CollpesBtn });
    event.preventDefault();
    this.props.getAdvanceSearch(this.state);

  }

  handleClickSearch= (event) => {
    if(this.state.CollpesBtn === 'EXPAND'){
      var CollpesBtn = 'COLLAPSE';
    }else{
      var CollpesBtn = 'EXPAND';
      this.setState({ toggleEvent: Date.now(),CollpesBtn:CollpesBtn });
    }
    let requestdata={};
    if(event === 'lasthours'){
      this.setState({ lastdaysclass:'',lasthoursclass:'active',mostwishlistedclass:'',bestsellingclass:'',mainclass:'',clicksearch:1,bestselling:'',mostwishlisted:'',lastdays:'',lasthours:'lasthours'});
      requestdata = this.state;
      requestdata.lasthours = 'lasthours';
      requestdata.clicksearch = 1;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.props.getAdvanceSearch(requestdata);
    }else if(event === 'lastdays'){
      this.setState({ lastdaysclass:'active',lasthoursclass:'',mostwishlistedclass:'',bestsellingclass:'',mainclass:'',clicksearch:1,bestselling:'',mostwishlisted:'',lastdays:'lastdays',lasthours:''});
      requestdata = this.state;
      requestdata.lastdays = 'lastdays';
      requestdata.clicksearch = 1;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.props.getAdvanceSearch(requestdata);
    }else if(event === 'mostwishlisted'){
      this.setState({ lastdaysclass:'',lasthoursclass:'',mostwishlistedclass:'active',bestsellingclass:'',mainclass:'',clicksearch:1,mostwishlisted:'mostwishlisted',bestselling:'',lastdays:'',lasthours:''});
      requestdata = this.state;
      requestdata.mostwishlisted = 'mostwishlisted';
      requestdata.clicksearch = 1;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.props.getAdvanceSearch(requestdata);
    }else if(event === 'bestselling'){
      this.setState({ lastdaysclass:'',lasthoursclass:'',mostwishlistedclass:'',bestsellingclass:'active',mainclass:'',clicksearch:1,bestselling:'bestselling',mostwishlisted:'',lastdays:'',lasthours:''});
      requestdata = this.state;
      requestdata.bestselling = 'bestselling';
      requestdata.clicksearch = 1;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.props.getAdvanceSearch(requestdata);
    }
    // event.preventDefault();

    // this.props.getAdvanceSearchClick(this.state);
    // const validation = this.validator.validate(this.state);
    // this.setState({
    //   validation
    // });
  }
  handleChangeCategory = (event) => {
    let requestdata= {};
    requestdata.catVal= 1;
    requestdata.subcatID=event.target.value;
    this.setState({
      'category': event.target.value,
      'categoryNamedata': event.target[event.target.selectedIndex].text,
    });
    this.props.getSubCategoryData(requestdata);
  }

  handleChangesubCategoryOne = (event) => {
    let requestdata= {};
    requestdata.catVal= 2;
    requestdata.subcatID=event.target.value;
    this.setState({
      'subcategoryone': event.target.value,
      'subcategoryoneNamedata': event.target[event.target.selectedIndex].text,
    });
    this.props.getSubCategoryOneData(requestdata);
  }

  handleChangesubCategoryTwo = (event) => {
    let requestdata= {};
    requestdata.catVal= 3;
    requestdata.subcatID=event.target.value;
    this.setState({
      'subcategorytwo': event.target.value,
      'subcategorytwoNamedata': event.target[event.target.selectedIndex].text,
    });
    this.props.getSubCategoryTwoData(requestdata);
  }

  handleChangesubCategoryThree = (event) => {
    let requestdata= {};
    requestdata.subcatID=event.target.value;
    this.setState({
      'subcategorythree': event.target.value,
      'subcategorythreeNamedata': event.target[event.target.selectedIndex].text,
    });
    this.props.getAdvanceSearch(requestdata);
  }


  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  render() {
    const AnyReactComponent = '';
    const countryDetails  = this.props.countryData;
    const categoryDetails  = this.props.categoryData;
    const subOneCategoryDetails  = this.props.subcategoryData;
    const subTwoCategoryDetails  = this.props.subonecategoryData;
    const subThreeCategoryDetails  = this.props.subtwocategoryData;
    const productDetailData = this.props.productData;
    const countryData = [];
    const categoryData = [];
    const categoryOneData = [];
    const categoryTwoData = [];
    const categoryThreeData = [];
    let loaderHTML = false;
    let buttonName = 'load more';
    let remaining = 0;
    let offset = this.state.offset;
    const productDetails  = this.props.advancesearchData;
    const productDetailsClick  = this.props.advancesearchClick;
    let productListData  = '';
    const optionsage = [];
    const age = ['01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21];

    for (var i = 0; i < age.length; i++) {
      var ageData ={};
      ageData['value'] = age[i];
      optionsage.push(ageData);
    }


    if(productDetailsClick !== null && productDetailsClick !== undefined){
        loaderHTML = true;
        productListData = productDetailsClick.product_list;
    }
    if(productDetails !== null && productDetails !== undefined){
        loaderHTML = true;
        productListData = productDetails.product_list;
        var listCount = productDetails.product_list.length;
        if(offset === 0){
            remaining = productDetails.totalCount - productDetails.product_list.length;
            if(remaining > 0){
                remaining = remaining;
            }else{
                remaining = 0;
            }
            // if($(".advance_search").is(":visible")){
            //     vm.CollpesBtn = "EXPAND";
            // }else{
            //     vm.CollpesBtn = "COLLAPSE";
            // }
            
        }else{
            remaining = remaining - listCount;
            if(remaining > 0){
                remaining = remaining;
            }else{
                remaining = 0;
            }
        }
        if(listCount > 0){
          var offsetData = offset + listCount;
          // this.setState({offset:offsetData})
        }
    }
    

    if(countryDetails !== null && countryDetails !== undefined){
      const countryListData = countryDetails.CountryList;
        
      for(var i = 0; i < countryListData.length; i++) {
          var countryDataMain = {};
          countryDataMain['CountryName'] = countryListData[i].CountryName;
          countryDataMain['ISOCode2'] = countryListData[i].ISOCode2;
          countryData.push(countryDataMain);
      }
    }


    if(categoryDetails !== null && categoryDetails !== undefined){
        
        const categoryListData = categoryDetails.Category;
        

      for(var i = 0; i < categoryListData.length; i++) {
          var categoryDataMain = {};
          categoryDataMain['id'] = categoryListData[i].id;
          categoryDataMain['category_name'] = categoryListData[i].category_name;
          categoryDataMain['Count'] = categoryListData[i].Count;
          categoryData.push(categoryDataMain);
      }
      if(subOneCategoryDetails !== null && subOneCategoryDetails !== undefined && subOneCategoryDetails !== ''){

          const categoryOneListData = subOneCategoryDetails.Category;

          for(var i = 0; i < categoryOneListData.length; i++) {
            var categoryOneDataMain = {};
            categoryOneDataMain['id'] = categoryOneListData[i].id;
            categoryOneDataMain['category_name'] = categoryOneListData[i].subcat1_name;
            categoryOneDataMain['Count'] = categoryOneListData[i].subcatOneCount;
            categoryOneData.push(categoryOneDataMain);
        }
      }

      if(subTwoCategoryDetails !== null && subTwoCategoryDetails !== undefined && subTwoCategoryDetails !== ''){

          const categoryTwoListData = subTwoCategoryDetails.Category;

          for(var i = 0; i < categoryTwoListData.length; i++) {
            var categoryTwoDataMain = {};
            categoryTwoDataMain['id'] = categoryTwoListData[i].id;
            categoryTwoDataMain['category_name'] = categoryTwoListData[i].subcat2_name;
            categoryTwoDataMain['Count'] = categoryTwoListData[i].subcatTwoCount;
            categoryTwoData.push(categoryTwoDataMain);
        }
      }

      if(subThreeCategoryDetails !== null && subThreeCategoryDetails !== undefined && subThreeCategoryDetails !== ''){

          const categoryThreeListData = subThreeCategoryDetails.Category;

          for(var i = 0; i < categoryThreeListData.length; i++) {
            var categoryThreeDataMain = {};
            categoryThreeDataMain['id'] = categoryThreeListData[i].id;
            categoryThreeDataMain['category_name'] = categoryThreeListData[i].subcat3_name;
            categoryThreeDataMain['Count'] = categoryThreeListData[i].subcatThreeCount;
            categoryThreeData.push(categoryThreeDataMain);
        }
      }

    }

    const loaderImg = require('../../assets/images/loader_img.gif');


    return (
      <span>
      <HeaderContainer />
      <Translator 
          from={this.state.preselectedLanguage}
          to={this.state.selectedLanguage}
          googleApiKey={serverUrl.googleApiTranslation}
        >
          <div className="main_content advanc_page">
            <div className="advanceSearchMenu">
              {/* MainMenu */}
              <MainMenuContainer />
            </div>
            
            <div className="container">
                <div className="form_toptxt">
                  <h2 className="form_head"><a href="#" className="Darkblue_btn"><i className="fas fa-search"></i></a><Translate>ADVANCED SEARCH</Translate></h2>
                  <p className="form_summary">
                  </p>
                  <div className="advace_form">
                    <div className="row margin_4">
                        <div className="col-6 col-md-5 ">
                            <div className="row margin_4">
                              <div className="col-6">
                                  <a className={"btn_dflt grey_btn ng-pristine ng-untouched ng-valid ng-empty buttonActive " + this.state.lasthoursclass} onClick={() => {this.handleClickSearch('lasthours')}} aria-invalid="false" id="day">
                                  <span>
                                      <Translate>Posted Last 24 Hours</Translate>
                                    </span></a>
                              </div>
                              <div className="col-6">
                                  <a className={"btn_dflt grey_btn ng-pristine ng-untouched ng-valid ng-empty buttonActive " + this.state.lastdaysclass} onClick={() => {this.handleClickSearch('lastdays')}} aria-invalid="false">
                                    <span>
                                       <Translate>Posted Last 30 Days</Translate>
                                    </span>
                                  </a>
                              </div>
                            </div>
             
                        </div>

                        <div className="col-6 col-md-2 ">
                            <a className={"btn_dflt grey_btn ng-pristine ng-untouched ng-valid ng-empty buttonActive " + this.state.bestsellingclass} onClick={() => {this.handleClickSearch('bestselling')}} aria-invalid="false">
                            <span>
                               <Translate>Best Selling</Translate>
                              </span></a>
                        </div>
                        <div className="col-6 col-md-2 ">
                            <a className={"btn_dflt grey_btn ng-pristine ng-untouched ng-valid ng-empty buttonActive " + this.state.mostwishlistedclass} onClick={() => {this.handleClickSearch('mostwishlisted')}} aria-invalid="false">
                            <span>
                               <Translate>Most Wishlisted</Translate>
                              </span></a>
                        </div>

                        <div className="col-6 col-md-3 dataadv">
                            <a href="#" className={"btn_dflt grey_btn advance_link toggle "+ this.state.mainclass} onClick={this.onToggle} > 
                            <span>{this.state.CollpesBtn}<br/><Translate>ADVANCED SEARCH</Translate></span></a>
                        </div>
                    </div>
                  </div>
                </div>
                <SlideToggle duration={1500}  toggleEvent={this.state.toggleEvent} >
                  {({ setCollapsibleElement }) => (
                <div className="wht_box advance_search my-collapsible__content"
                ref={setCollapsibleElement}>
                <form role="search"  onSubmit={this.handleClickKeyword}>
                  <div className="row margin_4">
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt">Keyword</label>
                        <input type="text" className="form-control advanceKeyword" name="keyword" value={this.state.keyword} onChange={this.handleInputChange} placeholder="Keyword"/>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                            <label className="label_txt required_fld">Location (City)</label>
                             <GooglePlacesAutocomplete apiKey="AIzaSyCUkGua9OzAQkNjb9OzfpQM4EP5aRLhgGE" selectProps={{ 
                            onChange: this.handleInputChangeApi,
                          }}/>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt required_fld"><Translate>Vendor</Translate></label>
                        <input type="text" className="form-control" name="vendor" onChange={this.handleInputChange} placeholder="Enter Vendor Name" />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt required_fld"><Translate>Product ID</Translate></label>
                        <input type="text" className="form-control" name="productid" value={this.state.productid} onChange={this.handleInputChange} placeholder="Enter Product ID" />
                      </div>
                    </div>
                  </div>
                  <div className="row margin_4">
                    <div className="col-sm-12 col-md-12">
                      <h4 className="form_subhading"><Translate>PROXIMITY:</Translate></h4>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <label className="cstm_radio2">
                          <input type="radio" ng-model="asc.searchParams.proximity" id="radio01" onChange={this.handleInputChange}  type="radio" name="optradio" />
                          <span className="check_mark"><span className="label_txt"><Translate>Location (City)</Translate></span></span>
                        </label>
                        <input type="text" className="form-control" id="city" value={this.state.radiusCity} name="radiusCity" onChange={this.handleInputChange} placeholder="Enter a location"/>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <label className="cstm_radio2">
                          <input type="radio" id="radio02" value="pcode" type="radio" onChange={this.handleInputChange} name="optradio"/>
                          <span htmlFor="test2" className="check_mark"><span className="label_txt"><Translate>Postal Code</Translate></span></span>
                        </label>
                        <input type="text" className="form-control" value ={this.state.radiusPostalCode} name="radiusPostalCode" onChange={this.handleInputChange} placeholder="Postal Code"/>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="label_txt">Within</label>
                        <div className="custom_box">
                          <label className="cstm_radio2">
                            <input type="radio" id="test3" name="radiusUnit" onChange={this.handleInputChange} value="km" id="ki_me"/>
                            <span htmlFor="test3" className="check_mark"><span className="label_txt"><Translate>Km</Translate></span></span>
                          </label>
                          <label className="cstm_radio2">
                            <input type="radio" name="radiusUnit" id="miles" onChange={this.handleInputChange} value="miles" />
                            <span htmlFor="test4" className="check_mark"><span className="label_txt"><Translate>Miles</Translate></span></span>
                          </label>
                        </div>
                        <input type="number" className="form-control" onChange={this.handleInputChange} name="radiuswithin" id="usr2" placeholder="Radius" />
                      </div>
                    </div>
                  </div>
                  <div className="row margin_4 ">
                    <div className="col-sm-12 col-md-12">
                      <h4 className="form_subhading"><Translate>CATEGORIES:</Translate></h4>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt"><Translate>Category</Translate></label>
                        <select className="form-control" name="category" onChange={this.handleChangeCategory}>
                          <option value="">Select Category</option>
                          {categoryData.map(({ id,category_name,Count }, index) => <option key={id} value={id}>{category_name}</option> )}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt required_fld"><Translate>Sub-Category 1</Translate></label>
                        <select className="form-control" name="subcategoryone" onChange={this.handleChangesubCategoryOne} >
                          <option value="">Select SubCategory 1</option>
                          {categoryOneData.map(({ id,category_name,Count }, index) => <option key={id} value={id}>{category_name}</option> )}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt required_fld"><Translate>Sub-Category 2</Translate></label>
                        <select className="form-control" name="subcategorytwo" onChange={this.handleChangesubCategoryTwo}>
                          <option value="">Select SubCategory 2</option>
                          {categoryTwoData.map(({ id,category_name,Count }, index) => <option key={id} value={id}>{category_name}</option> )}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt required_fld"><Translate>Sub-Category 3</Translate></label>
                        <select className="form-control" name="subcategorythree" onChange={this.handleChangesubCategoryThree}>
                            <option value="">Select Subcategory 3</option>
                            {categoryThreeData.map(({ id,category_name,Count }, index) => <option key={id} value={id}>{category_name}</option> )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row margin_4 three_boxes mrb_15">
                    <div className="col-sm-12 col-lg-9">
                      <div className="row margin_4">
                      <div className="col-md-5">
                          <h4 className="form_subhading"><Translate>MARKET SEGMENTS</Translate></h4>
                          <div className="row margin_2">
                            <div className="col-sm-4 col-6">
                                <div className="custom_check">
                                  <input type="checkbox" data-two="B2B" id="a3" className="check3" name="marketsegments" onChange={this.handleInputChange}/>
                                  <label className="check_boxbtbn" htmlFor="a3"  title="Business To Business">
                                      B2B
                                    </label>

                                </div>
                            </div>
                            <div className="col-sm-4 col-6">
                                <div className="custom_check">
                                  <input type="checkbox" data-two="B2G" id="a4" className="check3" name="marketsegments" onChange={this.handleInputChange}/>
                                  <label className="check_boxbtbn" htmlFor="a4" title="Business To Goverment">
                                      B2G
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-6">
                                <div className="custom_check">
                                  <input type="checkbox" data-two="B2C" id="a7" className="check3" name="marketsegments" onChange={this.handleInputChange}/>
                                  <label className="check_boxbtbn" htmlFor="a7" title="Business To Consumer" >
                                      B2C
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-6">
                                <div className="custom_check">
                                  <input type="checkbox" data-two="M2B" id="a1" className="check3" name="marketsegments" onChange={this.handleInputChange}/>
                                  <label className="check_boxbtbn" htmlFor ="a1" title="Manufacturer To Business" >
                                      M2B
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-6">
                                <div className="custom_check">
                                  <input type="checkbox" data-two="M2G" id="a5" className="check3" name="marketsegments" onChange={this.handleInputChange}/>
                                  <label className="check_boxbtbn" htmlFor="a5" title="Manufacturer To Goverment">
                                      M2G
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-6">
                                <div className="custom_check">
                                  <input type="checkbox" data-two="M2C" id="a8" className="check3" name="marketsegments" onChange={this.handleInputChange}/>
                                  <label className="check_boxbtbn" htmlFor="a8" title="Manufacturer To Consumer">
                                      M2C
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-6">
                                <div className="custom_check">
                                  <input type="checkbox" data-two="G2B" id="a2" className="check3" name="marketsegments" onChange={this.handleInputChange}/>
                                  <label className="check_boxbtbn" htmlFor="a2" title="Goverment To Business">
                                      G2B
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-6">
                                <div className="custom_check">
                                  <input type="checkbox" data-two="G2G" id="a6" className="check3" name="marketsegments" onChange={this.handleInputChange}/>
                                  <label className="check_boxbtbn" htmlFor="a6" title="Goverment To Goverment">
                                      G2G
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-6">
                                <div className="custom_check">
                                  <input type="checkbox" id="a9" data-two="C2C"className="check3" name="marketsegments" onChange={this.handleInputChange}/>
                                  <label className="check_boxbtbn" htmlFor="a9" title="Consumer To Consumer">
                                      C2C
                                    </label>
                                </div>
                            </div>
                          </div>
                      </div>
                      <div className="col-md-7">
                          <h4 className="form_subhading"> PRODUCT OPTIONS </h4>
                          <div className="row margin_2 prodcut_optn">
                            <div className="col-sm-8 col-lg-9">
                                <div className="row margin_2">
                                  <div className="col-6">
                                      <div className="custom_check">
                                        <input type="checkbox" id="a10" data="New" className="check3" name="productoptionsnew" onChange={this.handleInputChange}/>
                                        <label className="check_boxbtbn" htmlFor="a10" >
                                            <Translate>New</Translate>
                                          </label>
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <div className="custom_check">
                                        <input type="checkbox" id="a13" data="Genuine" className="check3" name="productOptionsgenuine" onChange={this.handleInputChange}/>
                                        <label className="check_boxbtbn" htmlFor="a13">
                                            <Translate>Genuine</Translate>
                                          </label>
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <div className="custom_check">
                                        <input type="checkbox" id="a11" data="Used" className="check3" name="productoptionsnew" onChange={this.handleInputChange}/>
                                        <label className="check_boxbtbn" htmlFor="a11">
                                            <Translate>Used</Translate>
                                          </label>
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <div className="custom_check">
                                        <input type="checkbox" id="a14" data="Replica" className="check3" name="productOptionsgenuine" onChange={this.handleInputChange}/>
                                        <label className="check_boxbtbn" htmlFor="a14">
                                            <Translate>Replica</Translate>
                                          </label>
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <div className="custom_check">
                                        <input type="checkbox" id="a12" data="Refurbished" className="check3" name="productOptionsgenuine" onChange={this.handleInputChange}/>
                                        <label className="check_boxbtbn" htmlFor="a12">
                                            <Translate>Refurbished</Translate>
                                          </label>
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <div className="custom_check">
                                        <input type="checkbox" id="a15" data="Volume Discount" className="check3" name="volumediscount" onChange={this.handleInputChange}/>
                                        <label className="check_boxbtbn" htmlFor="a15">
                                            <Translate>Volume Discount</Translate>
                                          </label>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="col-sm-4 col-lg-3">
                              <div className="custom_check">
                                <input type="checkbox" className="check3" id="age" name="age" onChange={this.handleInputChange}/>
                                  <label className="check_boxbtbn" htmlFor="age">
                                    <Translate>Age</Translate>
                                  </label>
                              </div>
                              <select className="form-control" name="agenumber">
                                <option value="">select age</option> 
                                {optionsage.map(({ value }, index) => <option key={value} value={value}>{value}</option> )}
                              </select>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12 ship_optn">
                    <h4 className="form_subhading"><Translate> SHIPPING OPTIONS </Translate></h4>
                    <div className="shp_bx">
						<div className="custom_check">
						  <input type="checkbox" data-one="Shipping Required" id="ship_req" className="check3" name="shippingoptions" onChange={this.handleInputChange}/>
						  <label className="check_boxbtbn" htmlFor="ship_req">
							  <Translate>Shipping Required</Translate>
							</label>
						</div>
						<div className="custom_check">
						  <input type="checkbox" data-one="Available for Pickup" id="ava_pic" className="check3" name="pickupavailable" onChange={this.handleInputChange}/>
						  <label className="check_boxbtbn" htmlFor="ava_pic">
							  <Translate>Available for Pickup</Translate>
							</label>
						</div>
						<div className="custom_check">
						  <input type="checkbox" data-one="Download" id="Download" className="check3" name="download" onChange={this.handleInputChange}/>
						  <label className="check_boxbtbn" htmlFor="Download">
							  <Translate>Download</Translate>
							</label>
						</div>
                    </div>
                </div>
              </div>
              <div className="row margin_4">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="label_txt"><Translate>PRODUCTS LOCATED IN COUNTRY:</Translate></label>
                    <select className="form-control" name="productscountry" onChange={this.handleInputChange}>
                      <option value="">Select Country</option>
                      {countryData.map(({CountryName,ISOCode2}, index) => <option key={CountryName} value={ISOCode2}>{CountryName}</option> )}
                    </select>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="label_txt required_fld"><Translate>Pricing (USD)</Translate></label>
                    <div className="row margin_4">
                      <div className="col-6">
                        <input type="text" className="form-control"  id="usr" onChange={this.handleInputChange} name="pricingmin" value={this.state.pricingmin} placeholder="Minimum"/>
                      </div>
                      <div className="col-6">
                        <input type="text" className="form-control" id="usr1" onChange={this.handleInputChange} name="pricingmax" value={this.state.pricinmax} placeholder="Maximum"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row margin_4 mrb_15">
                  <div className="col-lg-6">
                    <div className="row margin_4">
                      <div className="col-sm-12 col-md-12">
                        <h4 className="form_subhading"> <Translate>RATINGS:</Translate> </h4>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <label className="label_txt"><Translate>Product Rating:</Translate></label>
                          <select className="form-control"  name="productrating" onChange={this.handleInputChange}>
                            <option value="">Select Product Rating</option>
                            <option value="1">{'\u2B50'}</option>
                            <option value="2">{'\u2B50'}{'\u2B50'}</option>
                            <option value="3">{'\u2B50'}{'\u2B50'}{'\u2B50'}</option>
                            <option value="4">{'\u2B50'}{'\u2B50'}{'\u2B50'}{'\u2B50'}</option>
                            <option value="5">{'\u2B50'}{'\u2B50'}{'\u2B50'}{'\u2B50'}{'\u2B50'}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <label className="label_txt"><Translate>Vendor Rating:</Translate></label>
                          <select className="form-control" name="vendorrating" onChange={this.handleInputChange}>
                            <option value="">Select Vendor Rating</option>
                            <option value="1">{'\u2B50'}</option>
                            <option value="2">{'\u2B50'}{'\u2B50'}</option>
                            <option value="3">{'\u2B50'}{'\u2B50'}{'\u2B50'}</option>
                            <option value="4">{'\u2B50'}{'\u2B50'}{'\u2B50'}{'\u2B50'}</option>
                            <option value="5">{'\u2B50'}{'\u2B50'}{'\u2B50'}{'\u2B50'}{'\u2B50'}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row margin_4">
                      <div className="col-sm-12 col-md-12">
                        <h4 className="form_subhading"><Translate>Date Posted:</Translate></h4>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <label className="label_txt"><Translate>After</Translate></label>
                          <input type="date" className="form-control" name="datepostedafter" onChange={this.handleInputChange} placeholder="mm/dd/yy" />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group mb-0">
                          <label className="label_txt"><Translate>Before</Translate></label>
                          <input type="date" className="form-control" name="datepostedbefore" onChange={this.handleInputChange} placeholder="mm/dd/yy" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row margin_4 botm_btn">
                  <div className="col-6">
                      <button type="submit" onClick={this.handleFormSubmit} className="btn btn_dflt blue_btn blu_btn_shadow submit"><Translate>Submit</Translate></button>
                  </div>
                </div>
              </div>
               </form>
                  <div className="resat col-12 text-end">
                    <button type="button" onClick={this.handleReset} className="btn btn_dflt grey_btn" ><Translate>Reset</Translate></button>
                  </div>
            </div>
            )}
            </SlideToggle>

            <div className="mep" style={{ height: '100vh', width: '100%', display: 'none' }}>
              <GoogleMapReact
                bootstrapURLKeys="AIzaSyClwVa0b-pjRixLGGgFHbLD6RtOHWlQb3A"
                defaultCenter={{ lat: 22.7196, lng: 75.8577 }}
                defaultZoom={5}

              >
               

            
              </GoogleMapReact>
            </div>
             
{productDetails !==undefined && productDetails !==null && this.state.offsetDataMain !== null? 

			<div className="wht_box srch_sec">
				<div className="table table-responsive">
					<table className="table table-bordered">
						<tr>
							<td><span className="sm_txt"><Translate>Total Results</Translate></span><span className="vl_txt">{productDetails.totalCount}</span></td>
              {this.state.city !==""?               
							  <td> <span className="sm_txt"><Translate>Location(City)</Translate></span><span className="vl_txt">{this.state.city}</span></td>
               :""
              }
              {this.state.vendor !=="" ?
                <td> <span className="sm_txt"><Translate>Vendor</Translate></span>  <span className="vl_txt"> {this.state.vendor} </span> </td>
               :""
              }   
               {this.state.marketsegmenttype.length > 0?
                 <td> <span className="sm_txt"><Translate>Market Segments</Translate></span>  <span className="vl_txt">{this.state.marketsegmenttype}</span> </td>
               :""
              }    

               {this.state.productoptiontype.length > 0  ?
                 <td> <span className="sm_txt"><Translate>Product Options</Translate></span> <span className="vl_txt"> {this.state.productoptiontype} </span></td>
                :""
              } 
               {this.state.productratingNamedata !== undefined ?
                <td> <span className="sm_txt"><Translate>Product Ratings</Translate></span>  <span className="vl_txt"> {this.state.productratingNamedata} </span> </td>
               :""
              } 
               {this.state.vendorratingNamedata !== undefined ?
                <td> <span className="sm_txt"><Translate>Vendor Ratings</Translate></span>  <span className="vl_txt"> {this.state.vendorratingNamedata} </span> </td>
               :""
              }  

               {this.state.km !== undefined ?
                <td> <span className="sm_txt"><Translate>Within(km)</Translate></span>  <span className="vl_txt"> {this.state.km} </span> </td>
               :""
              }    
                {this.state.shippingoptiontype.length > 0 ?
                 <td> <span className="sm_txt"><Translate>Shipping Options</Translate></span> <span className="vl_txt">{this.state.shippingoptiontype}</span></td>
                  :""
              } 
                {this.state.productid !== "" ?
                 <td> <span className="sm_txt"><Translate>Product ID</Translate></span> <span className="vl_txt">{this.state.productid}</span></td>
                 :""
                 }
						</tr>
             <tr> 
                {this.state.categoryNamedata !== undefined ?
                <td> <span className="sm_txt"><Translate>Main Category</Translate></span> <span className="vl_txt"> {this.state.categoryNamedata} </span></td>
                :""
              }
                {this.state.subcategoryoneNamedata !== undefined ?
                 <td> <span className="sm_txt"><Translate>Sub-Category 1</Translate></span> <span className="vl_txt"> {this.state.subcategoryoneNamedata} </span></td>
                 :""
              }
                {this.state.subcategorytwoNamedata !== undefined ?
                 <td> <span className="sm_txt"><Translate>Sub-Category 2</Translate></span> <span className="vl_txt"> {this.state.subcategorytwoNamedata} </span></td>
                  :""
              } 
                {this.state.subcategorythreeNamedata !== undefined ?
                 <td> <span className="sm_txt"><Translate>Sub-Category 3</Translate></span> <span className="vl_txt">{this.state.subcategorythreeNamedata}</span></td>
                  :""
              }
                {this.state.radiusPostalCode !== undefined ?
                 <td> <span className="sm_txt"><Translate>Postal Code</Translate></span> <span className="vl_txt">{this.state.radiusPostalCode}</span></td>
                  :""
              }
                {this.state.pricingmin !== "" ?
                 <td> <span className="sm_txt"><Translate>Pricing-Min</Translate></span> <span className="vl_txt">{this.state.pricingmin}</span></td>
                  :""
              }
                {this.state.pricingmax !== "" ?
                 <td> <span className="sm_txt"><Translate>Pricing-Max</Translate></span> <span className="vl_txt">{this.state.pricingmax}</span></td>
                  :""
              }
              

             </tr>
					</table>
				       </div>
              
				<div className="tbl_srcsec">
          {isMobile === false ?
  					<div className={this.state.isTop ? 'srb_bxinr' : 'srb_fixed srb_bxinr'}>
  						<input className="form-control dataBorder advancedSearchBar"  name="resultkeyword" placeholder="Refine Your Search" value={this.state.resultkeyword} onChange={this.handleInputChange} type="text" />
  						<button className="btn blue_btn search_btn" type="button" onClick={this.handleFormSubmitClick}><i className="fas fa-search"></i></button>
              </div>
  				     : <div className={this.state.isTop ? 'srb_bxinr' : 'srb_fixed srb_bxinr'}>
              <input className="form-control dataBorder"  name="resultkeyword" placeholder="Refine Your Search" value={this.state.resultkeyword} onChange={this.handleInputChange} type="text" />
              <button className="btn blue_btn search_btn" type="button" onClick={this.handleFormSubmitClick}><i className="fas fa-search"></i></button>
                </div>
                }

				</div>
			</div>
         : ""
               }


             
              {loaderHTML === false && this.state.keyword === null?
                <h1 align="center"><Translate>Searching</Translate></h1> :""
              }

              {productListData.length < 1 && loaderHTML !== false && this.state.offsetDataMain !== null?
                <h1 align="center"><Translate>No Product Found</Translate></h1> :""
              }

              {productDetails !==undefined && productDetails !==null ?
                  <ProductGrid productdata={productListData}/>
                :

                <div></div>
              }

            </div>
          </div>
          
           {/* {productDetails !== undefined && productDetails !== null ?
             <span>
             {offset !== 0 && productDetails.totalCount >12 ?
                <span>
                   <div align="center">
                    <img src={loaderImg} height="80px" width="80px"/>
                    <br/>
                   </div>
                </span>
                :""}
                <span ng-if="asc.buttonClass == 1">
                    <a id="loadMore" onClick ={this.handleClick} className="btn_dflt grey_btn">{buttonName}</a>
                </span>
                <div align="center">
                  <h2 className="totl_result"><Translate>Remaining Products:</Translate> {remaining } </h2>
                </div>
              </span>
              :""}
              */}
              <div className="partner_footer_label ">
                <p className="partner_contentCookies"><Translate>OFFICIAL PARTNER OF THE ROMANIAN RUGBY FEDERATION</Translate></p>
              </div>
          
          </Translator>
        {/* Footer */}
          <FooterContainer />
      </span>
    )
  }
}





